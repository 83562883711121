function CmParticipantAppointmentsDeleteController($scope, EntitiesService, MessageBox, $log, $location, $routeParams,
    $rootScope, ActivitiesSchedulesService) {
    let self = this;

    /* TODO: trigger and handle those events
    $scope.$on('eventSelected', (event, calendarView) => {
    });
    $scope.$on('eventUnselectedSelected', (event, calendarView) => {
    });
     */

    this.deleteSelected = function(roomId) {
        $location.search('resource', roomId);

        $rootScope.$broadcast('deleteCalendarAppointment');
    };
}

angular.module('activitiesScheduleComponents')
    .component('cmParticipantAppointmentsDelete', {
        templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/cm-participant-appointments-delete.template.html',
        controller: CmParticipantAppointmentsDeleteController,
        bindings: {
        }
    });
