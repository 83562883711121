asc.controller('ActivitiesSchedulePreferencesModalController', function($scope, $uibModalInstance, title, message, Constants,
    ActivitiesSchedulesService, EntitiesService, AssociatesDaoService) {

    //if message is an object, put all its properties in $scope
    if (angular.isObject(message)) {
        angular.extend($scope, message);
    } else {
        $scope.message = message;
    }

    $scope.title = title;

    $scope.gridOptions = {
        multiSelect: false,

        columnDefs: [{
                field: "firstName",
                name: "Nome",
                //width: 220,
                enableCellEdit: false,
                headerTooltip: true
            },
            {
                field: "lastName",
                name: "Cognome",
                //width: 220,
                enableCellEdit: false,
                headerTooltip: true
            },
            {
                field: "color",
                name: "Colore",
                //width: "*",
                cellTemplate: '<div class="ui-grid-cell-contents" ><div style="width:20px; height: 20px; background-color: {{grid.getCellValue(row, col)}}">&nbsp;</div></div>',
                editableCellTemplate: 'ui-grid/dropdownEditor',
                editDropdownOptionsArray: Constants.COLOR_OPTIONS
            }
        ]
    };
    $scope.gridOptions.onRegisterApi = function(gridApi) {
        //set gridApi on scope
        $scope.gridApi = gridApi;
        gridApi.rowEdit.on.saveRow($scope, $scope.saveRow);
    };

    $scope.saveRow = function(rowEntity) {
        // create a fake promise - normally you'd use the promise returned by $http or $resource
        var promise = EntitiesService.save(rowEntity);
        $scope.gridApi.rowEdit.setSavePromise(rowEntity, promise);

    };

    AssociatesDaoService.loadCounterparts(Constants.ROLE_COLLABORATOR, {
            only_docs: true
        })
        .then(function(counterparts) {
            $scope.gridOptions.data = counterparts;
        });

    $scope.ok = function(courseSchedule, form) {
        return $scope.gridApi.rowEdit.flushDirtyRows($scope.gridApi.grid)
            .then(function() {
                return $uibModalInstance.close();
            })
    };

    console.log(title, message);
});
