"use strict";

// var app = angular.module('app');

twaCC.factory('AssociatesDaoService', function($q, Http, $log, EntitiesService) {

    var api = {
        loadAssociates: function(opts) {
            var options = {
                filter: function(associate) {
                    return associate._id.indexOf("Input") == -1;
                }
            };

            options = _.assign(options, opts);
            return EntitiesService.load("associate", options);
        },

        loadAssociatesRegistry: function(options) {
            var config = {
                params: options
            };
            return Http.get("api/associates/registry", config);
        },

        loadCounterparts: function(type, options) {
            var config = {
                params: options
            };
            return Http.get("api/associates/counterparts/" + type, config);
        }
    };

    return api;
});
