"use strict";

twaCC.service('EntitiesService', function($http, UtilsService, Http, ImageService) {

    var api = {

        create: function(idPrefix, options) {
            options = _.assign({
                hideable: false
            }, options);
            var entity = {
                _id: UtilsService.uuid(idPrefix)
            };
            if (options.hideable) {
                entity.name = "";
                entity.createdDate = new Date();
            }
            return entity;
        },

        save: function(entity) {
            return Http.put("api/entities/" + entity._id + "/", entity)
                .then(function(resp) {
                    var ack = resp.data;
                    entity._rev = ack.rev;
                    return resp;
                })
        },

        bulkDocs: function(entities) {
            return Http.post("api/entities/bulk_docs/", entities)
                .then(function(acks) {
                    acks.forEach(function(ack, idx) {
                        var entity = entities[idx];
                        entity._rev = ack.rev;
                    });
                    return acks;
                });
        },

        getAttachment: function(docId, attachmentId) {
            return Http.get("api/entities/attachment/" + docId + "/" + attachmentId)
                .then(function(attachment) {
                    var attachmentB64 = ImageService.arrayBufferToBase64(attachment.data);
                    return 'data:;base64,' + attachmentB64;
                });
        },

        putAttachment: function(doc, attachmentId, payload, mimeType) {
            return Http.put("api/entities/attachment/" + doc._id + "/" + attachmentId + "?docRev=" + doc._rev,
                    payload, {
                        headers: {
                            'Content-Type': mimeType
                        },
                        transformRequest: []
                    })
                .then(function(resp) {
                    var ack = resp.data;
                    doc._rev = ack.rev;
                    return resp;
                });
        },

        deleteAttachment: function(entity, attachmentId) {
            return Http.delete("api/entities/attachment/" + entity._id + "/" + attachmentId + "?docRev=" + entity._rev)
                .then(function(ack) {
                    entity._rev = ack.rev;
                });
        },

        load: function(inputSubject, options) {
            var config = undefined;
            if (options) {
                if (options.keys && options.keys.length == 0) {
                    return Q.when([]);
                }
                var remoteOptions = angular.copy(options);
                delete remoteOptions.filter;
                config = {
                    params: remoteOptions
                };
            }
            return Http.get("api/entities/" + inputSubject + "/", config)
                .then(function(entities) {
                    if (options) {
                        if (options.deleted === false) {
                            entities = entities.filter(function(entity) {
                                return !entity.deleted;
                            })
                        }
                        if (options.filter) {
                            entities = entities.filter(options.filter)
                        }
                    }
                    return entities;
                })
                .catch(function(error) {
                    $log.error("load " + inputSubject, error);
                });
        },

        listToNamesMap: function(entityList) {
            var entityNamesMap = entityList.reduce(
                function(acc, curr) {
                    acc[curr._id] = curr.name;
                    return acc;
                }, {})
            return entityNamesMap;
        },

        listToMap: function(entityList) {
            var entitiesMap = entityList.reduce(
                function(acc, curr) {
                    acc[curr._id] = curr;
                    return acc;
                }, {})
            return entitiesMap;
        },

        computePaymentsInfo: function(entity) {
            if (entity.payments) {
                var paymentsAmount = entity.payments.reduce(function(prev, curr) {
                    return prev + curr.amount;
                }, 0);
                entity.payedAmount = paymentsAmount;
            }
            if (entity.nextPayments) {
                var res = api.computeTotalToPay(entity.nextPayments || []);
                entity.toBePayedAmount = res.totalToPay;
                entity.firstPaymentDate = res.firstPaymentDate;
            } else if (entity.payedAmount != undefined) {
                var expiredAmount = 0;
                if (moment().isAfter(entity.date), 'day') {
                    expiredAmount = entity.amount;
                }
                entity.toBePayedAmount = Math.max(0, expiredAmount - entity.payedAmount);
                if (entity.toBePayedAmount > 0) {
                    entity.firstPaymentDate = entity.date;
                }
            }
            return entity;
        },

        get: function(inputSubject, id, opts) {
            return Http.get("api/entities/" + inputSubject + "/" + id, {
                params: {
                    opts: opts
                }
            });
        },

        delete: function(id, rev) {
            if (rev == null) {
                var input = id;
                id = input._id;
                rev = input._rev;
            }
            return Http.delete("api/entities/" + id + "/" + rev);
        },

        deleteBulk: function(entities) {
            var deleteCommands = entities.map(function(entity) {
                return {
                    _id: entity._id,
                    _rev: entity._rev,
                    _deleted: true
                };
            });
            return Http.post("api/entities/bulk_docs/", deleteCommands);
        },

        hide: function(entity) {
            return Http.post("api/entities/hide", entity);
        },

        unhide: function(entity) {
            return Http.post("api/entities/unhide", entity);
        },

        loadView: function(designDocId, queryId, options) {
            return Http.post("api/entities/view/" + designDocId + "/" + queryId + "/", options);
        },

        /*
         sum of expired rates at date
         */
        computeTotalToPay: function(nextPayments, date) {
            if (!date)
                date = new Date();
            var paymentsToPay = nextPayments.filter(function(payment) {
                if (moment(payment.date).isSameOrBefore(date, 'day')) {
                    return true;
                }
            });

            var nextPaymentDate;
            if (paymentsToPay.length > 0) {
                nextPaymentDate = paymentsToPay[0].date;
            } else if (nextPayments.length > 0) {
                nextPaymentDate = nextPayments[0].date;
            }
            var ratesTotal = paymentsToPay.reduce(function(prev, curr) {
                return prev + curr.amount;
            }, 0);

            var res = {
                firstPaymentDate: nextPaymentDate,
                totalToPay: ratesTotal
            };

            return res;
        },

        dbInfo: function(dbId) {
            return Http.get("api/entities/dbInfo/" + dbId);
        },
    };

    return api;
});
