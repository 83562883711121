function CmActivityColorPickerController($scope, $element, $attrs, UIService, uiGridConstants) {
    this.$onInit = function() {
        return;
    };

    //https://sashat.me/2017/01/11/list-of-20-simple-distinct-colors/
    this.colorPickerColors = [
        '#800000',
        '#aa6e28',
        '#808000',
        null,
        null,
        '#008080',
        '#000080',
        null,
        null,
        '#000000',

        '#e6194b',
        '#f58231',
        '#ffe119',
        '#d2f53c',
        '#3cb44b',
        '#46f0f0',
        '#0082c8',
        '#911eb4',
        '#f032e6',
        '#808080',


        '#fabebe',
        '#ffd8b1',
        '#fffac8',
        null,
        '#aaffc3',
        null,
        null,
        '#e6beff',
        null,
        '#FFFFFF'
    ];

    this.colorPickerOptions = {
        columns: 10,
        size: 30,
        roundCorners: true,
        colorNames: {
            '#800000': 'Bordeaux',
            '#e6194b': 'Rosso',
            '#fabebe': 'Rosa',
            '#aa6e28': 'Marrone',
            '#f58231': 'Arancione',
            '#ffd8b1': 'Corallo',
            '#808000': 'Verde Oliva',
            '#ffe119': 'Giallo',
            '#fffac8': 'Beige',
            '#d2f53c': 'Lime',
            '#3cb44b': 'Verde',
            '#aaffc3': 'Verde Menta',
            '#008080': 'Verde Acqua',
            '#46f0f0': 'Ciano',
            '#000080': 'Blu Navy',
            '#0082c8': 'Blu',
            '#911eb4': 'Viola',
            '#e6beff': 'Lavanda',
            '#f032e6': 'Magenta',
            '#000000': 'Nero',
            '#808080': 'Grigio',
            '#FFFFFF': 'Bianco'
        }
    };
}

angular.module('activitiesScheduleComponents')
    .component('cmActivityColorPicker', {
        templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/cm-activity-color-picker.template.html',
        controller: CmActivityColorPickerController,
        bindings: {
            activitySchedule: '<'
        }
    });
