"use strict";

twaCC.factory('UtilsService', function($log, $http) {
    var api = {
        copyObjectPropertiesInTemplate: function(srcObj, templateObj) {
            var destObj = {};
            for (var key in srcObj) {
                if (templateObj.hasOwnProperty(key) && srcObj.hasOwnProperty(key)) {
                    destObj[key] = srcObj[key];
                }
            }
            return destObj;
        },

        removeArrayValue: function(arr, value) {
            if (arr) {
                var idx = arr.indexOf(value);
                if (idx != -1) {
                    arr.splice(idx, 1);
                    return arr;
                }
            }
            return arr;
        },

        ambiguousToLocalMoment: function(m) {
            return moment(m.format());
        },

        dateFormat: function(date) {
            if (date != null && angular.isDate(date)) {
                return date.toLocaleDateString("it-IT", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric"
                })
            } else {
                return date;
            }
        },

        dateFormatISOLocalTime: function(date) {
            if (date != null && angular.isDate(date)) {
                return moment(date).format().substr(0, 10);
            } else {
                return date;
            }
        },

        timeFormat: function(date) {
            if (date != null && angular.isDate(date)) {
                return date.toLocaleTimeString("it-IT")
            } else {
                return date;
            }
        },

        //check if time represents time
        isTime: function(time) {
            var re = /\d\d\:\d\d/
            return re.test(time);
        },

        /*
         transforms a long array in an array of arrays of length <= batchSize. For each small array fun is called with
         it as argument
         */
        batchProcess: function(array, batchSize, fun) {
            var i, j, batch, res = [];
            for (i = 0, j = array.length; i < j; i += batchSize) {
                batch = array.slice(i, i + batchSize);
                res.push(fun(batch));
            }
            return res;
        },

        uuid: function(type) {

            //http://guid.us/GUID/JavaScript
            /*function S4() {
             return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
             }
             var rawUuid = (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();

             if (!type) {
             return rawUuid;
             } else {
             return type + "_" + rawUuid;
             }*/
            //return type + "_" + api.toFixed(Math.floor(1e31 + Math.random() * 9e31));
            function S4i() {
                return ((Math.random() * 10000) | 0).toString();
            }

            var rawUuid = (S4i() + S4i() + S4i() + S4i() + S4i() + S4i() + S4i() + S4i());
            if (!type) {
                return rawUuid;
            } else {
                return type + "_" + rawUuid;
            }
        },

        toFixed: function(x) {
            if (Math.abs(x) < 1.0) {
                var e = parseInt(x.toString().split('e-')[1]);
                if (e) {
                    x *= Math.pow(10, e - 1);
                    x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
                }
            } else {
                var e = parseInt(x.toString().split('+')[1]);
                if (e > 20) {
                    e -= 20;
                    x /= Math.pow(10, e);
                    x += (new Array(e + 1)).join('0');
                }
            }
            return x;
        },

        uniqueArrayValues: function(a) {
            var prims = {
                    "boolean": {},
                    "number": {},
                    "string": {}
                },
                objs = [];

            return a.filter(function(item) {
                var type = typeof item;
                if (type in prims)
                    return prims[type].hasOwnProperty(item) ? false : (prims[type][item] = true);
                else
                    return objs.indexOf(item) >= 0 ? false : objs.push(item);
            });
        },

        roundToCents: function(v) {
            var res = Math.round(v * 100) / 100;
            return res;
        },

        loadApplicationVersion: function() {
            return $http.get('package.json').then(function(json) {
                priv.applicationVersion = json.data.version;
                return json.data.version;
            });
        },

        getApplicationVersion: function() {
            return priv.applicationVersion;
        },

        padLeft: function(len, str, padCh) {
            var ans = Array(len - str.length + 1).join(padCh) + str
            return ans;
        },

        currentLangId: function() {
            //http://stackoverflow.com/questions/1043339/javascript-for-detecting-browser-language-preference
            //(window.navigator.userLanguage || window.navigator.language).substring(0.2);
            return "it";
        },

        //TODO: at the moment it works only for arrays
        shallowCopy: function(src, dst) {
            if (dst === undefined) {
                return src.slice(0, src.length);
            }
            Object.assign(dst, src);
            dst.length = src.length;
        },

        /*
         check if newValue is unique among the values of fieldName for all AssociateSummaries
         */
        isUniqueValue: function(entities, fieldName, newValue) {
            if (!newValue || !entities) {
                return true;
            }
            for (var i = 0; i < entities.length; i++) {
                if (entities[i][fieldName] == newValue) {
                    return false;
                }
            }
            return true;
        },

        isEmptyMap: function(map) {
            if (angular.isObject(map)) {
                return Object.keys(map).length == 0;
            } else {
                throw new ApplicationException("object is not a map");
            }
        },


        getFingerPrints: function() {
            return priv.fingerPrint;
        },

        checkpoint: function(obj) {
            obj.$$loadedValue = api._dehydrate(obj);
        },

        wasModifiedAfterCheckpoint: function(obj) {
            return !angular.equals(obj.$$loadedValue, api._dehydrate(obj));
        },

        convertDateStringsToDates: function(input) {
            let regexIso8601 = /^(\d{4}|\+\d{6})(?:-(\d{2})(?:-(\d{2})(?:T(\d{2}):(\d{2}):(\d{2})\.(\d{1,})(Z|([\-+])(\d{2}):(\d{2})))))$/;
            // Ignore things that aren't objects.
            if (typeof input !== "object") return input;

            for (var key in input) {
                if (!input.hasOwnProperty(key)) continue;

                var value = input[key];
                var match;
                // Check for string properties which look like dates.
                if (typeof value === "string" && (match = value.match(regexIso8601))) {
                    var milliseconds = Date.parse(match[0])
                    if (!isNaN(milliseconds)) {
                        input[key] = new Date(milliseconds);
                    }
                } else if (typeof value === "object") {
                    // Recurse into object
                    api.convertDateStringsToDates(value);
                }
            }
        },

        _hydrate: function(obj, deserializer) {
            if (!deserializer)
                deserializer = api._standardHydrater
            var jsonStr = JSON.stringify(obj);

            var objH2O = JSON.parse(jsonStr, deserializer);
            return objH2O;
        },

        _standardHydraterRegEx: /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d*)Z$/,

        _standardHydrater: function(key, value) {
            if (typeof value === 'string') {
                //  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/
                var a = api._standardHydraterRegEx.exec(value);
                if (a) {
                    return new Date(Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4], +a[5], +a[6], +a[7]));
                }
            }
            return value;
        },

        _importHydrater: function(key, value) {
            if (typeof value === 'string') {
                if (/^[\d\.]+$/.test(value))
                    return Number(value);
                var a = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/.exec(value)
                if (a) {
                    return moment([+a[3], +a[2] - 1, +a[1]]).toDate();
                }
            }
            return value;
        },

        _dehydrate: function(objH2O, serializer) {
            if (!serializer)
                serializer = api._standardDehydrater
            var jsonStr = JSON.stringify(objH2O, serializer);

            var obj = JSON.parse(jsonStr);
            return obj;
        },

        _standardDehydrater: function(key, value) {
            if (key.startsWith("$$")) //properties starting with $$ are transient
                return undefined;
            return value;
        },

        _importDehydrater: function(key, value) {
            var a = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/.exec(value);
            if (a) {
                return moment(value).format("D/M/YYYY");
            }
            if (key.startsWith("$$"))
                return undefined;
            return value;
        },

        httpGet: function(url) {
            return $http.get(url)
                .then(function(resp) {
                    return resp.data;
                })
                .catch(function(error) {
                    throw error.data;
                })
        },

        getValueFromMap: function(map, indexes) {
            var res = map[indexes[0]];
            if (res !== null && typeof res == 'object') {
                if (_.isArray(res)) {
                    res = res.indexOf(indexes[1]) != -1;
                } else {
                    res = res[indexes[1]];
                }
            }
            return res;
        },

        getTokensFromId: function(id) {
            var idx = id.lastIndexOf("_");
            var prefix = id.substring(0, idx);
            var tokens = prefix.split("-");
            return tokens;
        },

        moneyGt: function(a, b) {
            return a - b > 0.01;
        },

        moneyGtEq: function(a, b) {
            return a - b > -0.01;
        },


        moneyLt: function(a, b) {
            return a - b < -0.01;
        },

        moneyLtEq: function(a, b) {
            return a - b < 0.01;
        },

        moneyEq: function(a, b) {
            return abs(a - b) < 0.01;
        },

        inputSubjectToIdPrefix: function(inputSubject) {
            var idPrefix = _.camelCase(inputSubject);
            var tokens = inputSubject.split("_");
            if (tokens.length == 3 && tokens[2] == "input")
                idPrefix = tokens[0] + "_" + _.camelCase(tokens[1] + "_" + tokens[2]);
            return idPrefix;
        },

        /*autocomplete: function(val, url) {
         var valLC = val ? val.toLowerCase() : val;
         return $http.get(url)
         .then(function(response) {
         var itemsList = response.data.split("\n");
         itemsList = itemsList.filter(function(item) {
         if (item.toLowerCase()
         .startsWith(valLC)) {
         return true;
         } else {
         return false;
         }
         });
         return itemsList;
         });
         },*/

        autocomplete: function(val, itemsList) {
            var valLC = val ? val.toLowerCase() : val;
            itemsList = itemsList.filter(function(item) {
                if (Array.isArray(item)) {
                    item = item[0];
                }
                if (item.toLowerCase()
                    .startsWith(valLC)) {
                    return true;
                } else {
                    return false;
                }
            });
            return itemsList;
        },

        loadCsv: function(url, singleValue) {
            return $http.get(url)
                .then(function(response) {
                    var itemList = response.data.split("\n");
                    if (singleValue != true) {
                        itemList = itemList.map(function(item) {
                            return item.split(",");
                        });
                    }
                    return itemList;
                });
        },

        downloadLink: function(link, openInNewTab) {
            let target = "_self";
            if (openInNewTab) {
                target = "_blank";
            }
            var anchor = angular.element('<a/>');
            anchor.attr({
                href: link,
                target: target
            })[0].click();
        },

        downloadBlob: function(result, filename) {
            let blob=new Blob([result]);
            let link=document.createElement('a');
            let url = link.href=window.URL.createObjectURL(blob);
            link.download=filename;
            link.click();
            //release the reference to the file by revoking the Object URL
            window.URL.revokeObjectURL(url);
        },

        downloadCsv: function(table, filename) {
            var csv = "";
            var columnDelimiter = ";";
            var lineDelimiter = "\n";

            table.forEach(function(row) {
                row.forEach(function(value, valueIdx) {
                    if (valueIdx > 0) csv += columnDelimiter;

                    if (value !== null && value !== undefined) {
                        csv += '"'+value+'"';
                    }
                });
                csv += lineDelimiter;
            });

            var anchor = angular.element('<a/>');
            anchor.attr({
                href: 'data:attachment/csv;charset=utf-8,' + encodeURIComponent(csv),
                target: '_blank',
                download: filename
            })[0].click();
        },

        downloadXmlx: function(filename, data) {
            var blob = data;
            var fileName = "tesseramenti.xlsx";
            var fileURL = URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = fileURL;
            a.target = '_blank';
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
        },

        arraysToGraphStreamValues: function(x, y) {
            var values = [];
            for (var i = 0; i < x.length; i++) {
                values.push({
                    x: x[i],
                    y: y[i]
                })
            }
            return values;
        },

        containsAny: function(str, values) {
            //return values.some(el => str.indexOf(el) > -1);
            var res = false;
            values.forEach(function(value) {
                if (str.indexOf(value) != -1)
                    res = true;
            });
            return res;
        }
    };

    var priv = {
        applicationVersion: null,
        fingerPrint: null,
    };

    moment.locale(api.currentLangId());

    new Fingerprint2().get(function(result, components) {
        priv.fingerPrint = result;
        $log.info("fingerprint: " + result); //a hash, representing your device fingerprint
        //$log.info("fingerprint components: "+JSON.stringify(components)); // an array of FP components
    });

    return api;
});

if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(searchString, position) {
        position = position || 0;
        return this.substr(position, searchString.length) === searchString;
    };
}

if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function(search, replacement) {
        var target = this;
        return target.replace(new RegExp(search, 'g'), replacement);
    };
}

Number.isFloat = function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
};

Object.defineProperty(Array.prototype, 'rotate', {
    enumerable: false,
    value: function(i) {
        if (i == 0) return this;
        const s = (this.splice).apply(this, i < 0 ? [i] : [0, i]);
        (i < 0 ? this.unshift : this.push).apply(this, s);
        return this;
    }
});
