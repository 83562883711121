function CmActivityBookingsController($scope, $element, $attrs, UIService, uiGridConstants, AssociatesDaoService,
    Constants) {

    CmAssociateGridController.call(this, $scope, $element, $attrs, UIService, uiGridConstants, AssociatesDaoService,
        Constants);

    this.createColumnDefs = () => {
        const columnDefs = [{
                field: "contactId",
                name: "Nome",
                //width: 190,
                cellTemplate: '<div class="ui-grid-cell-contents"><a href="" ' +
                    'ng-click="grid.appScope.itemClick(grid.getCellValue(row, col))">' +
                    '{{grid.appScope.getItemName(grid.getCellValue(row, col))}}</a></div>'
            },
            {
                field: "confirmed",
                name: "Presente",
                width: 100,
                cellTemplate: `<div 
                    class="ui-grid-cell-contents glyphicon {{grid.getCellValue(row, col)?'glyphicon-thumbs-up':'glyphicon-thumbs-down'}}"
                    style="width:100%;color: {{grid.getCellValue(row, col)?'#398439':'#d43f3a'}}"  
                    ng-click="grid.appScope.toggleConfirmation(row)"></div>`
            }
        ];
        return columnDefs;
    };

    this.addItem = function(item) {
        if (this.items.indexOf(item) == -1) {
            this.items.push({
                contactId: item,
                confirmed: true
            });

        }
        this.item = null;
    };

    this.toggleConfirmation = function(row) {
        row.entity.confirmed = !row.entity.confirmed;
    };

    /*this.createReceipt = function(associateId) {
        this.onCreateReceiptClick({
            associateId: associateId
        })
    }*/
}

angular.module('activitiesScheduleComponents')
    .component('cmActivityBookings', {
        templateUrl: 'components/form-components/cm-associate-grid.template.html',
        controller: CmActivityBookingsController,
        bindings: {
            items: '<',
            setItems: '&',
            itemIdsList: '<',
            itemNamesMap: '<',
            itemsAreClickable: '<',
            onItemClick: '&',
            //onCreateReceiptClick: '&',
            width: '<'
        }
    });
