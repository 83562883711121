"use strict";

twaCC.factory('SettingsService', function($log, $q, ImageService, UtilsService, Http, AssociatesDaoService, EntitiesService) {
    var api = {

        loadSettings: function(opts) {
            return EntitiesService.get("settings", "settings", opts)
                .then(function(settings) {
                    priv.settings = settings;
                    return settings;
                })
        },

        saveSettings: function(settings) {
            return EntitiesService.save(settings)
                .then(function(ack) {
                    priv.settings = settings;
                    return settings;
                });
        },

        getSettings: function() {
            if (priv.settings) {
                return priv.settings;
            } else {
                throw "Settings uninitialized";
            }
        },

        getLogo: function() {
            if (priv.settings) {
                if (priv.settings._attachments && priv.settings._attachments.logo) {
                    return 'data:;base64,' + priv.settings._attachments.logo.data;
                } else {
                    return priv.defaultLogo;
                }
            } else {
                throw "Settings uninitialized";
            }
        },

        setSettings: function(settings) {
            priv.settings = settings;
        },

        loadOffices: function() {
            return Http.get("api/settings/offices");
        },

        createTimeSlot: function() {
            return {
                name: "Mattino",
                startTime: "06:30",
                endTime: "13:00"
            };
        }

    };

    var priv = {
        settings: null,
    };

    ImageService.convertImgToBase64URL('imgs/placeholder.gif', function(base64Img) {
        priv.defaultLogo = base64Img;
    });

    return api;
});
