"use strict";

twaCC.factory('ProductsService', function($log, $q, UtilsService, Constants, Http, EntitiesService) {

    var api = {

        loadProducts: function(productType, showDeleted) {
            return Http.get("api/products", {
                    params: {
                        showDeleted: showDeleted,
                        productType: productType
                    }
                })
                .then(function(products) {
                    products = products.map(api.decorateProductWithMethods);
                    return products;
                });
        },

        loadProductNamesMap: function(productType, showDeleted) {
            return Http.get("api/products/namesMap", {
                params: {
                    showDeleted: showDeleted,
                    productType: productType
                }
            });
        },

        findAccessControlledMap: function(productIds) {
            const uniqueProductIds = [...new Set(productIds)];
            return Http.get("api/products/accessControlled", {
                params: {
                    ids: JSON.stringify(uniqueProductIds)
                }
            });
        },

        createProduct: function(productType) {
            var product = EntitiesService.create("product", {
                hideable: true
            });
            product = _.assign(product, {
                schemaVersion: Constants.SCHEMA_VERSION,
                type: productType,
                name: '',
                price: 0,
                offices: []
                //duration: 1
            });
            if (productType == Constants.PRODUCT_TYPE_ASSOCIATION_FEE) {
                product.name = "Quota Associativa";
                product.associationFee = true;
                product.duration = 12;
            }
            /*var id = UtilsService.uuid("product");
            product._id = id;*/
            product = api.decorateProductWithMethods(product);
            return product;
        },

        decorateProductWithMethods: function(product) {
            product.isExtraService = function() {
                return product.type == Constants.PRODUCT_TYPE_EXTRA_SUBSCRIPTION;
            };
            product.getLabel = function() {
                let label = product.name;
                if (product.offices && product.offices.length > 0) {
                    label += " (" + product.offices.join(", ") + ")";
                }
                return label;
            };
            return product;
        },

        /*saveProduct: function(product) {
            return Http.put("api/products/" + product._id, product);
        },

        deleteProduct: function(product) {
            return Http.post("api/products/delete", product);
        },

        undeleteProduct: function(product) {
            return Http.post("api/products/undelete", product);
        },*/

        //TODO: review
        isCourseSubscription: function(product) {
            if (product.type == Constants.PRODUCT_TYPE_BASIC_SUBSCRIPTION) {
                return true;
            }
        }

    };

    return api;
});
