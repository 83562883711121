function CmActivityScheduleController($scope, $element, $attrs, Constants, ActivitiesSchedulesService, uiGridConstants,
    UIService, $location, AuthenticationService, MessageBox, UtilsService, ActivitiesService, CalendarSchedulesService) {

    let self = this;

    CmScheduleController.call(this, $scope, $element, $attrs, Constants, CalendarSchedulesService, uiGridConstants,
        UIService, $location, AuthenticationService, MessageBox, UtilsService);

    this.SCHEDULING_TYPE_APPOINTMENT = Constants.SCHEDULING_TYPE_APPOINTMENT;
    this.SCHEDULING_TYPE_APPOINTMENTS_LIST = Constants.SCHEDULING_TYPE_APPOINTMENTS_LIST;
    this.SCHEDULING_TYPE_WEEKLY_APPOINTMENTS = Constants.SCHEDULING_TYPE_WEEKLY_APPOINTMENTS;

    this.$onInit = function() {
        self.init();
        this.associateIds = this.associates.map((associate) => associate.id);
        this.associateNamesMap = this.associates.reduce(function(acc, associate) {
            acc[associate.id] = associate.label;
            return acc;
        }, {});

        if (this.isCreatingCourseSchedule) {
            var authority = AuthenticationService.getCurrentAuthority();
            if (authority.userRole == Constants.USER_ROLE_ACTIVITY_MANAGER) {
                this.courseSchedule.instructorId = authority.userid;
                this.disableInstructorSelection = true;
            }
        }

        if (this.courseSchedule.participants == null) {
            this.courseSchedule.participants = [];
        }
        //console.log(title, message);
        UtilsService.checkpoint(this.courseSchedule);
    };

    this.activityHasAttendance = function() {
        if (this.selectedActivityEvent &&
            this.selectedActivityEvent.activityScheduleHasAttendance) {
            this.selectActivityDisabledReason = "Cancella le presenze per modificare l'attività";
            return true;
        } else {
            this.selectActivityDisabledReason = null;
            return false;
        }
    };

    this.onParticipantClick = function(associateId) {
        window.open(`index.html#!/inputs_associate/${associateId}_input`, 'associateInputs');
    };

    this.onCreateReceiptClick = function(associateId) {
        ActivitiesService.suggestProductsIncludingActivities(this.courseSchedule.courseId)
            .then(productIds => {
                const init = {
                    receipt: {
                        date: this.courseSchedule.startDate
                    },
                    products: productIds
                };
                const initStr = JSON.stringify(init);
                window.open(`index.html#!/inputs/${associateId}_input/attivita/_?init=${initStr}`, 'associateInputs');
            })
    };

    this.setParticipants = function(participants) {
        this.courseSchedule.participants = participants;
        UtilsService.checkpoint(this.courseSchedule);
    };

    this.onCourseChanged = function() {
        let course = this.courses.find(
            el => el._id == this.courseSchedule.courseId
        );
        let courseDuration = course.appointmentDuration;
        if (!courseDuration) {
            courseDuration = 60; //defaults to 60 min
        }
        $scope.$broadcast('courseDurationChanged', courseDuration);
        return;
    };

}

angular.module('activitiesScheduleComponents')
    .component('cmActivitySchedule', {
        templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/cm-activity-schedule.club.template.html',
        controller: CmActivityScheduleController,
        bindings: {
            selectedActivityEvent: '<',
            courseSchedule: '<',
            lesson: '<',
            courses: '<',
            instructors: '<',
            associates: '<',
            rooms: '<',
            isCreatingCourseSchedule: '<',
            colorList: '<',
            calendarEl: '<',
            uibModalInstance: '<'
        }
    });
