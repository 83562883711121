function CmActivityParticipantController($scope, $element, $attrs, AssociatesDaoService, Constants) {

    this.$onInit = function() {
        this.itemsList = this.itemIdsList.map((id) => {
            return {
                id: id,
                label: this.itemNamesMap[id]
            }
        });
        if (this.items.length > 0) {
            this.item = this.items[0];
        }
    };

    this.refreshItems = function() {
        AssociatesDaoService.loadCounterparts(Constants.ROLE_CONTACT)
            .then(associates => {
                this.itemIdsList = associates.map((associate) => associate.id);
                this.itemNamesMap = associates.reduce(function(acc, associate) {
                    acc[associate.id] = associate.label;
                    return acc;
                }, {});
                this.itemsList = this.itemIdsList.map((id) => {
                    return {
                        id: id,
                        label: this.itemNamesMap[id]
                    }
                });
            });
    };

    this.openAssociatesPage = function() {
        window.open(`index.html#!/inputs/associate`, 'associateInputs');
    };

    this.getItemName = function(itemId) {
        return this.itemNamesMap[itemId] ? this.itemNamesMap[itemId] : itemId;
    };

    this.onSelect = function($item, $model, $label, $event) {
        this.setItem($model);
    };

    this.setItem = function(itemId) {
        this.items.length = 0;
        this.items.push(itemId);
    }

    this.isDeleteItemDisabled = function() {
        return this.items.length == 0;
    };

    this.deleteItem = function() {
        this.item = null;
        this.items.length = 0;
    };

    this.openAssociatePage = function() {
        if (!this.item) {
            window.open(`index.html#!/inputs/associate`, 'associateInputs');
        } else {
            this.onItemClick({
                itemId: this.item
            })
        }
    };
}

angular.module('activitiesScheduleComponents')
    .component('cmActivityParticipant', {
        templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/cm-activity-participant.template.html',
        controller: CmActivityParticipantController,
        bindings: {
            items: '<',
            itemIdsList: '<',
            itemNamesMap: '<',
            onItemClick: '&',
            width: '<'
        }
    });
