//angular.module('app').value('Constants', constants);
if (!window.shared) {
    window.shared = {};
}
function initConstants(){
    var constants = {

        ESTRATTO_CONTO_PREFIX: "estrattoConto",

        /*roles: [{
                value: 'contact',
                label: 'Contatto'
            },
            {
                value: 'associate',
                label: 'Socio'
            },
            {
                value: 'collaborator',
                label: 'Socio e Collaboratore'
            }
        ],*/

        ROLE_COLLABORATOR: 'collaborator',
        ROLE_ASSOCIATE: 'associate',
        ROLE_CONTACT: 'contact',

        ASSOCIATES_LIST_REPORT_PROPERTIES: {
            id: true,
            admissionDate: true,
            firstName: true,
            lastName: true,
            birthDate: true,
            birthPlace: true,
            ssn: true,
            city: true,
            address: true,
            cellPhone: true,
            email: true
            //isQuotaAssociativaPayed: true
        },
        ASSOCIATES_LIST_REPORT_HEADER: ["Id", "Data Ammissione", "Nome", "Cognome", "Data Nascita", "Luogo Nascita",
            "C. Fiscale", "Città", "Via", "Cellulare", "Email"],
        MONTH_NAMES: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto",
            "Settembre", "Ottobre", "Novembre", "Dicembre"
        ],
        ASSOCIATE_MANDATORY_PROPERTIES: ["firstName", "lastName", "birthDate", "birthPlace",
            "city", "zipCode", "address", "ssn"
        ],

        SEX_MALE: "male",
        SEX_FEMALE: "female",

        DOC_TYPE_ID_CARD: "IDCard",
        DOC_TYPE_DRIVING_LICENSE: "IDCard",
        DOC_TYPE_RESIDENCY_PERMIT: "IDCard",
        DOC_TYPES: [{
            id: "IDCard",
            name: "Carta d'Identità"
        }, {
            id: "DrivingLicense",
            name: "Patente"
        },
            {
                id: "ResidencyPermit",
                name: "Permesso di Soggiorno"
            }
        ],

        INPUT_TYPE_ASSOCIATION_FEE: "quota_associativa",
        INPUT_TYPE_BASIC_SUBSCRIPTION: "attivita",
        INPUT_TYPE_EXTRA_SUBSCRIPTION: "servizio_extra",
        INPUT_TYPE_EVENT_SUBSCRIPTION: "sottoscrizione_evento",

        PRODUCT_BADGE: "product_badge",
        ENTITY_TYPE_PREDEFINED: "predefined",
        PRODUCT_TYPE_ALL: "all_products",
        PRODUCT_TYPE_ASSOCIATION_FEE: "association_fee",
        PRODUCT_TYPE_BASIC_SUBSCRIPTION: "subscription",
        PRODUCT_TYPE_EXTRA_SUBSCRIPTION: "extraservice_subscription",
        PRODUCT_TYPE_EVENT_SUBSCRIPTION: "event_subscription", //included in extra_service subscriptions

        SUBSCRIPTION_TYPE_TIME_BASED: "timeSubscription",
        SUBSCRIPTION_TYPE_ENTRANCE_BASED: "entrancesSubscription",
        SUBSCRIPTION_TYPE_SINGLE_ENTRANCE: "singleEntrance",
        SUBSCRIPTION_TYPE_DATES_BASED: "datesSubscription",

        SUBSCRIPTION_INCLUDED_ACTIVITIES_ALL: "all",
        SUBSCRIPTION_INCLUDED_ACTIVITIES_ALL_LABEL: "Tutte le attività del contributo",
        SUBSCRIPTION_INCLUDED_ACTIVITIES_WEIGHT_ROOM: "weightRoom",

        PAYMENT_TYPE_BANK: "banca",
        PAYMENT_TYPE_CASH: "contanti",

        ACTIVITY_WEIGHT_ROOM: "weightRoom",
        ACTIVITY_WEIGHT_ROOM_LABEL: "Sala Pesi",

        RECEIPT_TYPE_NON_FISCAL: "nonFiscalReceipt",
        RECEIPT_TYPE_FISCAL: "fiscalReceipt",
        RECEIPT_TYPE_INVOICE: "invoice",

        FATTURA_VENDITA: "FatturaVendita",
        FATTURA_ACQUISTO: "FatturaAcquisto",
        RICEVUTA_NON_FISCALE_VENDITA: "RicevutaNonFiscaleVendita",

        REPETITION_WEEKLY: "weekly",
        REPETITION_NONE: "none",

        SELECTED_EVENTS_THIS: "thisEvent",
        SELECTED_EVENTS_THIS_AND_FUTURE: "thisAndFutureEvents",
        SELECTED_EVENTS_ALL: "allEvents",

        SCHEDULING_TYPE_APPOINTMENT: "appointment",
        SCHEDULING_TYPE_APPOINTMENTS_LIST: "appointmentsList",
        SCHEDULING_TYPE_WEEKLY_APPOINTMENTS: "weeklyAppointments",

        COSTO_FISSO: "CostoFisso",
        COSTO_VARIABILE: "CostoVariabile",
        NUMERO_CONVERSIONI_A_SOCIO: "NumeroConversioniASocioSoci",
        NUMERO_SOCI: "NumeroSoci",
        ALTRO_RICAVO: "AltroRicavo",
        NUMERO_VISITE: "NumeroVisite",
        CANALE_CONTATTO: "CanaleContatto",
        RICAVI_ISTITUZIONALI: "RicaviIstituzionali",
        RICAVI_ISTITUZIONALI_ATTIVITA: "RicaviIstituzionaliAttivita",
        RICAVI_ISTITUZIONALI_SERVIZI_EXTRA: "RicaviIstituzionaliServiziExtra",
        NUMERO_ABBONAMENTI_VENDUTI: "NumeroAbbonamentiVenduti",

        COST_CENTER_BUSINESS: "business",
        COST_CENTER_BUSINESS_LABEL: "Tutti",

        ADMINISTRATOR_ID: "user_admin",
        USER_ROLE_ADMINISTRATOR: "administrator",
        USER_ROLE_RECEPTIONIST: "receptionist",
        USER_ROLE_ACTIVITY_MANAGER: "activityManager",

        /*USER_ROLES: [{
                _id: 'administrator',
                name: 'Amministratore'
            },
            {
                _id: 'receptionist',
                name: 'Receptionist'
            },
            {
                _id: 'activityManager',
                name: 'Istruttore'
            }
        ],*/

        USER_ANONYMOUS: {
            id: "anonymous",
            firstName: "Anonymous",
            lastName: "",
            role: "administrator"
        },

        COLOR_OPTIONS: [{
            id: null,
            value: ''
        },
            {
                id: '#800000',
                value: 'Bordeaux'
            },
            {
                id: '#e6194b',
                value: 'Rosso'
            },
            {
                id: '#fabebe',
                value: 'Rosa'
            },

            {
                id: '#aa6e28',
                value: 'Marrone'
            },
            {
                id: '#f58231',
                value: 'Arancione'
            },
            {
                id: '#ffd8b1',
                value: 'Corallo'
            },

            {
                id: '#808000',
                value: 'Verde Oliva'
            },
            {
                id: '#ffe119',
                value: 'Giallo'
            },
            {
                id: '#fffac8',
                value: 'Beige'
            },

            {
                id: '#d2f53c',
                value: 'Lime'
            },

            {
                id: '#3cb44b',
                value: 'Verde'
            },
            {
                id: '#aaffc3',
                value: 'Verde Menta'
            },

            {
                id: '#008080',
                value: 'Verde Acqua'
            },
            {
                id: '#46f0f0',
                value: 'Ciano'
            },

            {
                id: '#000080',
                value: 'Blu Navy'
            },
            {
                id: '#0082c8',
                value: 'Blu'
            },

            {
                id: '#911eb4',
                value: 'Viola'
            },
            {
                id: '#e6beff',
                value: 'Lavanda'
            },

            {
                id: '#f032e6',
                value: 'Magenta'
            },

            {
                id: '#000000',
                value: 'Nero'
            },
            {
                id: '#808080',
                value: 'Grigio'
            },
            {
                id: '#FFFFFF',
                value: 'Bianco'
            }
        ],

        SCHEMA_VERSION: "1.4.4",

        //Video tutorials
        URL_PANORAMICA: "https://youtu.be/ybCwuuQROkc",
        URL_CONFIGURAZIONE_ASSOCIAZIONE: "https://youtu.be/Gvad_EM01hA",
        URL_CONFIGURAZIONE_CONTRIBUTI_ASSOCIATIVI: "https://youtu.be/q6AfijDWlbo",
        URL_CONFIGURAZIONE_CLIENTI_FORNITORI_BANCHE: "https://youtu.be/oF-6WHUcrsc",
        URL_INSERIMENTO_CONTATTO_VISITA_SOCIO: "https://youtu.be/PUHKw9sSypg",
        URL_INSERIMENTO_SOTTOSCRIZIONE: "https://youtu.be/5FzZ150OuoM",
        URL_ORDINAMENTI_ELENCO_SOCI: "https://youtu.be/J47HDERXvnA",
        URL_ATTIVITA: "https://youtu.be/J4vQw-vwLxU",
        URL_TESSERAMENTI: "https://youtu.be/2n_fKonQJA4",
        URL_INSERIMENTO_ENTRATE_USCITE_COMMERCIALI: "https://youtu.be/ztmjoP5nuW4",
        URL_REPORTS_DEL_BILANCIO: "https://youtu.be/qTs6aKZUu6M",
        URL_RIMBORSI_SPESE_TRASFERTA: "https://youtu.be/Xp_rvdlg_uo",
        URL_LIQUIDAZIONE_IVA: "https://youtu.be/CtWHF_Cy9tA",
        URL_PAGAMENTI_COLLABORATORI: "https://youtu.be/8ieAjFj8IG0",
        URL_RITENUTE_PREVIDENZA_TASSE: "https://youtu.be/kyE6BD7uP2E"
    };

    let map = {};
    map[constants.PRODUCT_TYPE_ASSOCIATION_FEE] = constants.INPUT_TYPE_ASSOCIATION_FEE;
    map[constants.PRODUCT_TYPE_BASIC_SUBSCRIPTION] = constants.INPUT_TYPE_BASIC_SUBSCRIPTION;
    map[constants.PRODUCT_TYPE_EXTRA_SUBSCRIPTION] = constants.INPUT_TYPE_EXTRA_SUBSCRIPTION;
    map[constants.PRODUCT_TYPE_EVENT_SUBSCRIPTION] = constants.INPUT_TYPE_EVENT_SUBSCRIPTION;
    constants.PRODUCT_TYPE_INPUT_TYPE_MAP = map;

    return constants;
}

window.shared.constants = initConstants();
twaCC.factory('Constants', function($rootScope) { //, $translateProvider
    /*$translateProvider.translations('it_IT', {
     "IDCard": "Carta d'Identità",
     "DrivingLicense": "Patente",
     "ResidencyPermit": "Permesso di soggiorno"
     });
     $translateProvider.preferredLanguage('it_IT');*/

    let constants = initConstants();
    $rootScope.constants = constants;
    return constants;

});
