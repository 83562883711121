function CmActivityScheduleCalendarCollaboratorsController($scope, EntitiesService, MessageBox, $log, $location, $routeParams,
    $rootScope, ActivitiesSchedulesService) {
    let self = this;

    this.$onInit = function() {
        self.collaboratorId = $routeParams.collaborator ? $routeParams.collaborator : null;
        self.initialized = false;
        self.collaboratorItems = [{
            id: null,
            label: ""
        }].concat(self.collaborators);
    };

    $scope.$on('scheduleCalendarBeforeEventsRender', (event, calendarView) => {
        if (!self.initialized) {
            self.initialized = true;
            if (self.collaboratorId) {
                self.changeCollaborator(self.collaboratorId);
            }
        }
    });

    this.changeCollaborator = function(collaboratorId) {
        $location.search('collaborator', collaboratorId);

        $rootScope.$broadcast('drawCalendarEvents', {
            backgroundResourceId: collaboratorId
        });
    };



}

angular.module('activitiesScheduleComponents')
    .component('cmActivityScheduleCalendarCollaborators', {
        templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/cm-activity-schedule-calendar-collaborators.template.html',
        controller: CmActivityScheduleCalendarCollaboratorsController,
        bindings: {
            collaborators: '<'
        }
    });
