"use strict";

twaCC.service('ActivitiesService', function(EntitiesService, Http, $q, Constants) {

    this.loadActivities = function(opts) {
        return Http.get("/api/activities", {
            params: opts
        });
    };

    this.listToNamesMap = function(activities) {
        var activityNamesMap = EntitiesService.listToNamesMap(activities);
        activityNamesMap[Constants.SUBSCRIPTION_INCLUDED_ACTIVITIES_ALL] =
            Constants.SUBSCRIPTION_INCLUDED_ACTIVITIES_ALL_LABEL;
        return activityNamesMap;
    };

    this.getActivityNamesMap = function(showDeleted, activities) {
        return (function() {
                if (activities) {
                    return $q.when(activities)
                }
                return EntitiesService.load("activity", {
                    showDeleted: showDeleted
                })
            }())
            .then(function(activities) {
                return activities.reduce(function(acc, curr) {
                    acc[curr._id] = curr.name;
                    return acc;
                }, {});
            });
    };

    this.suggestProductsIncludingActivities = function(activityId) {
        return Http.get(`/api/activities/productsIncludingActivity/${activityId}`);
    }
});
