"use strict";

twaCC.service('AlertService', function() {
    var alerts = {};
    this.getAlerts = function(key) {
        if (alerts[key] === undefined) {
            alerts[key] = [];
        }
        return alerts[key];
    };
    this.addAlert = function(alert, key) {
        if (alerts[key] === undefined) {
            alerts[key] = [];
        }
        alerts[key].push(alert);
    };

    this.setAlert = function(message, key) {
        alerts[key] = [message];
    };

    this.closeAlert = function(index, key) {
        alerts[key].splice(index, 1);
    };

    this.clear = function(key) {
        alerts[key] = [];
    };

});
