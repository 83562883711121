function CmScheduleController($scope, $element, $attrs, Constants, CalendarSchedulesService, uiGridConstants,
    UIService, $location, AuthenticationService, MessageBox, UtilsService) {

    let self = this;

    this.SCHEDULING_TYPE_APPOINTMENT = Constants.SCHEDULING_TYPE_APPOINTMENT;
    this.SCHEDULING_TYPE_APPOINTMENTS_LIST = Constants.SCHEDULING_TYPE_APPOINTMENTS_LIST;
    this.SCHEDULING_TYPE_WEEKLY_APPOINTMENTS = Constants.SCHEDULING_TYPE_WEEKLY_APPOINTMENTS;

    this.$onInit = function() {
        self.init();
    };

    this.init = function() {
        let calMoment = this.calendarEl.fullCalendar('getDate');
        calMoment = UtilsService.ambiguousToLocalMoment(calMoment);
        let calView = this.calendarEl.fullCalendar('getView');
        if (calView.name == "agendaWeek" && calMoment.week() == moment().week()) {
            calMoment = moment();
        }

        if (this.isCreatingCourseSchedule) {
            var startDate = moment(calMoment).hour(12).minutes(0).seconds(0).millisecond(0).toDate();
            CalendarSchedulesService.addLesson(this.courseSchedule, startDate);
            this.lesson = this.courseSchedule.lessons[0];
            //calMoment is ambiguously zoned
            CalendarSchedulesService.compactActivityScheduleDates(this.courseSchedule);
        }

        //console.log(title, message);
        UtilsService.checkpoint(this.courseSchedule);
    }

}

angular.module('activitiesScheduleComponents')
    .component('cmSchedule', {
        templateUrl: 'bower_components/twa-common-components/frontend/src/cm-schedule.template.html',
        controller: CmScheduleController,
        bindings: {
            selectedActivityEvent: '<',
            courseSchedule: '<',
            lesson: '<',
            isCreatingCourseSchedule: '<',
            calendarEl: '<',
            uibModalInstance: '<',
            collaborators: '<'
        }
    });
