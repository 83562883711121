"use strict";

// var app = angular.module('app');

twaCC.factory('AccountingService', function($q, $http, $log, SettingsService, Http, EntitiesService, Constants) {

    const M_SP_RISULTATO_ESERCIZIO = "risultato_esercizio";
    const M_SP_FONDO_ESERCIZI_PRECEDENTI = "fondo_esercizi_precedenti";

    const M_SP_CASSA = "a_cassa";
    const M_SP_CREDITI_VERSO_CLIENTI = "a_crediti_verso_clienti";
    const M_SP_CREDITI_VERSO_SOCI = "a_crediti_verso_soci";
    const M_SP_DEBITI_VERSO_CLIENTI = "p_debiti_verso_clienti";
    const M_SP_DEBITI_VERSO_SOCI = "p_debiti_verso_soci";
    const M_SP_DEBITI_VERSO_FORNITORI = "p_debiti_verso_fornitori";
    const M_SP_DEBITI_VERSO_COLLABORATORI = "p_debiti_verso_collaboratori";
    const M_SP_PERSONALE_CONTO_RETRIBUZIONE = "p_personale_conto_retribuzione";
    const M_SP_PREVIDENZA_DIPENDENTI = "p_previdenza_dipendenti";
    const M_SP_PREVIDENZA_COLLABORATORI = "p_previdenza_collaboratori";
    const M_SP_DEBITI_STATO = "p_debiti_stato";
    const M_SP_DEBITI_PER_RITENUTE_DIPENDENTI = "p_debiti_per_ritenute_dipendenti";
    const M_SP_DEBITI_PER_RITENUTE_COLLABORATORI = "p_debiti_per_ritenute_collaboratori";
    const M_SP_IVA_NOSTRO_DEBITO = "p_iva_nostro_debito";
    const M_SP_IVA_CONTO_LIQUIDAZIONE = "p_iva_conto_liquidazione";
    const M_SP_MUTUO = "p_mutuo";

    const M_CE_QUOTE_ASSOCIATIVE = "r_quote_associative";
    const M_CE_CONTRIBUTI_ASSOCIATIVI = "r_contributi_associativi";
    const M_CE_SERVIZI_COMMERCIALI = "r_servizi_commerciali";
    const M_CE_SPONSOR = "r_sponsor";
    const M_CE_DONAZIONI = "r_donazioni";
    const M_CE_CONTRIBUTI_DA_ENTI = "r_contributi_da_enti";
    const M_CE_RACCOLTA_FONDI = "r_raccolta_fondi";
    //const M_REFA_MERCE_CONTO_VENDITA = "r_merce_conto_vendita";
    const M_CE_ATTIVITA_COMMERCIALE = "r_attivita_commerciale";
    const M_CE_IVA_FORFETTARIO = "r_forfettario_iva";

    const M_CE_INTERESSI_ATTIVI = "r_interessi_attivi";
    const M_CE_INTERESSI_PASSIVI = "c_interessi_passivi";

    const M_CE_COLLABORATORI = "c_collaboratori";
    const M_CE_STIPENDI = "c_stipendi";
    const M_CE_ONERI_SOCIALI = "c_oneri_sociali";
    const M_CE_IMPOSTE_E_TASSE = "c_imposte_e_tasse";
    const M_CE_ONERI_BANCARI = "c_oneri_bancari";
    const M_CE_AFFILIAZIONI_E_TESSERAMENTI = "c_affiliazioni_e_tesseramenti";
    const M_CE_ALIMENTI_E_BEVANDE = "c_alimenti_e_bevande";
    const M_CE_ATTREZZATURE = "c_attrezzature";
    const M_CE_MERCI_CONTO_ACQUISTI = "c_beni_e_merci";
    const M_CE_SERVIZI = "c_servizi";
    const M_CE_MANUTENZIONE = "c_manutenzione";
    const M_CE_UTENZE_ACQUA = "c_utenze_acqua";
    const M_CE_UTENZE_ELETTRICITA = "c_utenze_elettricita";
    const M_CE_UTENZE_GAS = "c_utenze_gas";
    const M_CE_UTENZE_TELEFONO = "c_utenze_telefono";
    const M_CE_UTENZE_ALTRO = "c_utenze_altro";
    const M_CE_MATERIALE_DI_CONSUMO = "c_materiale_di_consumo";
    const M_CE_FITTI_PASSIVI = "c_affitto_sede";
    const M_CE_VIAGGI_E_TRASFERIMENTI = "c_viaggi_e_trasferimenti";
    const M_CE_RIMBORSI_KILOMETRICI = "c_rimborsi_kilometrici";
    const M_CE_VITTO = "c_vitto";
    const M_CE_ALLOGGIO = "c_alloggio";
    const M_CE_SOFTWARE = "c_software";
    const M_CE_RIMBORSI_PIE_DI_LISTA = "c_rimborsi_pie_di_lista";

    const M_REFA_ANTICIPAZIONI_DA_SOCI = "e_anticipazioni_da_soci";
    const M_REFA_RESTITUZIONE_ANTICIPAZIONI_DA_SOCI = "u_restituzione_anticipazioni_da_soci";
    const M_REFA_IVA_LIQUIDATA = "u_iva_liquidata";
    const M_REFA_RITENUTE_DIPENDENTI = "u_ritenute_dipendenti";
    const M_REFA_RITENUTE_COLLABORATORI = "u_ritenute_collaboratori";
    const M_REFA_PREVIDENZA_DIPENDENTI = "u_previdenza_cipendenti";
    const M_REFA_MUTUO = "e_mutuo";
    const M_REFA_RATA_MUTUO = "u_rata_mutuo";

    var api = {

        getCurrentAnnoSocialeStartDate: function(date) {
            if (!date)
                date = new Date();
            var yyyy = date.getFullYear();
            var mm = date.getMonth();

            var seasonStart = SettingsService.getSettings().seasonStart;
            if (mm < seasonStart) {
                yyyy -= 1;
            }
            return new Date(yyyy, seasonStart, 1)
        },

        getAnniSociali: function() {
            return SettingsService.loadSettings()
                .then(function(settings) {
                    var anniSociali = [];
                    var currentAnnoSocialeStartDate = api.getCurrentAnnoSocialeStartDate();
                    var currentAnnoSocialeStartYear = currentAnnoSocialeStartDate.getFullYear();
                    var settings = SettingsService.getSettings();

                    function createAnnoSociale(year, seasonStart) {
                        var label = "";
                        if (seasonStart == 0)
                            label += year;
                        else
                            label += year + "/" + (year + 1);
                        var res = {
                            label: label,
                            startDate: new Date(year, seasonStart, 1)
                        };
                        res = res.startDate;
                        return res;
                    }

                    var nextAnnoSociale = createAnnoSociale(settings.openingYear, settings.seasonStart)

                    for (var year = settings.openingYear; year <= currentAnnoSocialeStartYear; year++) {
                        var annoSociale = nextAnnoSociale;
                        anniSociali.push(annoSociale);
                        nextAnnoSociale = createAnnoSociale(year + 1, settings.seasonStart);
                    }
                    return anniSociali;
                })
        },

        getAnniFiscali: function() {
            return SettingsService.loadSettings()
                .then(function(settings) {
                    var currentYear = moment().year();
                    var anniFiscali = [];
                    for (var year = settings.openingYear; year <= currentYear; year++) {
                        var annoFiscale = {
                            label: "" + year,
                            startDate: new Date(year, 0, 1)
                        };
                        annoFiscale = annoFiscale.startDate;
                        anniFiscali.push(annoFiscale);
                    }
                    return anniFiscali;
                });
        },

        loadRefa: function(costCenter, fiscalYear) {
            var fiscalYearStart = moment(fiscalYear).format("YYYY-MM");
            return Http.get(`api/reports/refa?fiscal-year=${fiscalYearStart}&costCenter=${costCenter}`)
                .then(function(data) {
                    var refa = api.mastriSumsToRefa(data);
                    return refa;
                });
        },

        mastriSumsToRefa: function(mastriSums) {
            var entrate = {
                aggregations: {},
                total: 0
            };
            var uscite = {
                aggregations: {},
                total: 0
            };

            var invertedEntrateAggregations = api.invertAggregations(api.refaAggregations.entrate);
            var invertedUsciteAggregations = api.invertAggregations(api.refaAggregations.uscite);

            for (var mastro in mastriSums) {
                if (mastro.startsWith("e_")) {
                    //entrate[mastro] = mastriSums[mastro];
                    var aggregation = invertedEntrateAggregations[mastro];
                    if (entrate.aggregations[aggregation] == null)
                        entrate.aggregations[aggregation] = {
                            total: 0,
                            mastri: {}
                        };
                    entrate.aggregations[aggregation].mastri[mastro] = mastriSums[mastro];
                    entrate.aggregations[aggregation].total += mastriSums[mastro];
                    entrate.total += mastriSums[mastro];
                } else {
                    //uscite[mastro] = mastriSums[mastro];
                    var aggregation = invertedUsciteAggregations[mastro];
                    if (uscite.aggregations[aggregation] == null)
                        uscite.aggregations[aggregation] = {
                            total: 0,
                            mastri: {}
                        };
                    uscite.aggregations[aggregation].mastri[mastro] = mastriSums[mastro];
                    uscite.aggregations[aggregation].total += mastriSums[mastro];
                    uscite.total += mastriSums[mastro];
                }
            }

            var refa = {
                entrate: entrate,
                uscite: uscite
            };
            return refa
        },

        refaAggregations: {
            entrate: {
                quote_associative: [
                    mastroCEToMastroRefa(M_CE_QUOTE_ASSOCIATIVE)
                ],
                contributi_associativi: [
                    mastroCEToMastroRefa(M_CE_CONTRIBUTI_ASSOCIATIVI)
                ],
                donazioni: [
                    mastroCEToMastroRefa(M_CE_DONAZIONI),
                    mastroCEToMastroRefa(M_CE_CONTRIBUTI_DA_ENTI),
                    mastroCEToMastroRefa(M_CE_RACCOLTA_FONDI)
                ],
                entrate_attivita_commerciale: [
                    mastroCEToMastroRefa(M_CE_SERVIZI_COMMERCIALI),
                    mastroCEToMastroRefa(M_CE_ATTIVITA_COMMERCIALE),
                    mastroCEToMastroRefa(M_CE_SPONSOR)
                ],
                altre_entrate: [
                    mastroCEToMastroRefa(M_CE_IVA_FORFETTARIO),
                    mastroCEToMastroRefa(M_CE_INTERESSI_ATTIVI),
                    M_REFA_MUTUO,
                    M_REFA_ANTICIPAZIONI_DA_SOCI
                ]
            },
            uscite: {
                acquisti_beni_e_merci: [
                    mastroCEToMastroRefa(M_CE_ALIMENTI_E_BEVANDE),
                    mastroCEToMastroRefa(M_CE_ATTREZZATURE),
                    mastroCEToMastroRefa(M_CE_MERCI_CONTO_ACQUISTI),
                    mastroCEToMastroRefa(M_CE_SOFTWARE),
                    mastroCEToMastroRefa(M_CE_MATERIALE_DI_CONSUMO)
                ],
                acquisti_servizi: [
                    mastroCEToMastroRefa(M_CE_SERVIZI),
                    mastroCEToMastroRefa(M_CE_MANUTENZIONE),
                    mastroCEToMastroRefa(M_CE_VIAGGI_E_TRASFERIMENTI),
                    mastroCEToMastroRefa(M_CE_RIMBORSI_KILOMETRICI),
                    mastroCEToMastroRefa(M_CE_VITTO),
                    mastroCEToMastroRefa(M_CE_ALLOGGIO),
                    mastroCEToMastroRefa(M_CE_RIMBORSI_PIE_DI_LISTA)
                ],
                utenze: [
                    mastroCEToMastroRefa(M_CE_UTENZE_ACQUA),
                    mastroCEToMastroRefa(M_CE_UTENZE_ELETTRICITA),
                    mastroCEToMastroRefa(M_CE_UTENZE_GAS),
                    mastroCEToMastroRefa(M_CE_UTENZE_TELEFONO),
                    mastroCEToMastroRefa(M_CE_UTENZE_ALTRO),
                ],
                godimento_beni_terzi: [
                    mastroCEToMastroRefa(M_CE_FITTI_PASSIVI)
                ],
                affilizazioni_e_tesseramenti: [
                    mastroCEToMastroRefa(M_CE_AFFILIAZIONI_E_TESSERAMENTI),
                ],
                costi_collaboratori: [
                    mastroCEToMastroRefa(M_CE_COLLABORATORI),
                    mastroCEToMastroRefa(M_CE_STIPENDI),
                ],
                costi_bancari: [
                    mastroCEToMastroRefa(M_CE_ONERI_BANCARI),
                    mastroCEToMastroRefa(M_CE_INTERESSI_PASSIVI)
                ],
                imposte_e_tasse: [
                    mastroCEToMastroRefa(M_CE_IMPOSTE_E_TASSE)
                ],
                altro: [
                    mastroCEToMastroRefa(M_CE_ONERI_SOCIALI),
                    M_REFA_IVA_LIQUIDATA,
                    M_REFA_RITENUTE_DIPENDENTI,
                    M_REFA_RITENUTE_COLLABORATORI,
                    M_REFA_PREVIDENZA_DIPENDENTI,
                    M_REFA_RATA_MUTUO,
                    M_REFA_RESTITUZIONE_ANTICIPAZIONI_DA_SOCI
                ]
            }
        },

        refaToUIRefa: function(refa) {
            var uiRefa = {
                entrate: {
                    aggregations: [],
                    total: refa.entrate.total
                },
                uscite: {
                    aggregations: [],
                    total: -refa.uscite.total
                }
            };
            var refaIdLabels = api.getRefaIdToLabels();
            api.refaAggregationsOrder.entrate.forEach(function(aggregation) {
                if (refa.entrate.aggregations[aggregation]) {
                    uiRefa.entrate.aggregations.push({
                        aggregationLabel: api.refaAggregationsIdLabels[aggregation],
                        total: refa.entrate.aggregations[aggregation].total
                    });
                    for (var mastro in refa.entrate.aggregations[aggregation].mastri) {
                        uiRefa.entrate.aggregations.push({
                            id: mastro,
                            label: refaIdLabels[mastro],
                            value: refa.entrate.aggregations[aggregation].mastri[mastro]
                        });
                    }
                }
            });
            api.refaAggregationsOrder.uscite.forEach(function(aggregation) {
                if (refa.uscite.aggregations[aggregation]) {
                    uiRefa.uscite.aggregations.push({
                        aggregationLabel: api.refaAggregationsIdLabels[aggregation],
                        total: -refa.uscite.aggregations[aggregation].total
                    });
                    for (var mastro in refa.uscite.aggregations[aggregation].mastri) {
                        uiRefa.uscite.aggregations.push({
                            id: mastro,
                            label: refaIdLabels[mastro],
                            value: -refa.uscite.aggregations[aggregation].mastri[mastro]
                        });
                    }
                }
            });
            return uiRefa;
        },

        refaAggregationsOrder: {
            entrate: ["quote_associative", "contributi_associativi", "donazioni", "entrate_attivita_commerciale",
                "altre_entrate"
            ],
            uscite: ["acquisti_beni_e_merci", "acquisti_servizi", "utenze", "godimento_beni_terzi",
                "affilizazioni_e_tesseramenti", "costi_collaboratori", "costi_bancari", "imposte_e_tasse", "altro"
            ]
        },

        refaAggregationsIdLabels: {
            quote_associative: "Quote associative",
            contributi_associativi: "Contributi associative",
            servizi_commerciali: "Servizi Commerciali",
            donazioni: "Donazioni",
            entrate_attivita_commerciale: "Attività commerciale",
            altre_entrate: "Altro",

            acquisti_beni_e_merci: "Acquisto beni e merci",
            acquisti_servizi: "Acquisto servizi",
            utenze: "Utenze",
            godimento_beni_terzi: "Godimento beni terzi",
            affilizazioni_e_tesseramenti: "Affiliazioni e tesseramenti",
            costi_collaboratori: "Costi collaboratori",
            costi_bancari: "Costi bancari",
            imposte_e_tasse: "Imposte e tasse",
            altro: "Altro"
        },

        refaIdLabels: [
            [M_REFA_IVA_LIQUIDATA, "Iva liquidata"],
            [M_REFA_RITENUTE_DIPENDENTI, "Ritenute dipendenti"],
            [M_REFA_RITENUTE_COLLABORATORI, "Ritenute collaboratori"],
            [M_REFA_PREVIDENZA_DIPENDENTI, "Previdenza dipendenti"],
            [M_REFA_MUTUO, "Mutuo"],
            [M_REFA_RATA_MUTUO, "Rata mutuo"],
            [M_REFA_ANTICIPAZIONI_DA_SOCI, "Anticipazioni da soci"],
            [M_REFA_RESTITUZIONE_ANTICIPAZIONI_DA_SOCI, "Restituzione anticipazioni"]
        ],

        getRefaIdToLabels: function() {
            var otherIdLabels = api.contoEconomicoIdLabels.map(function(idLabel) {
                var id = idLabel[0].replace("r_", "e_").replace("c_", "u_");
                var label = idLabel[1];
                return [id, label];
            })
            var refaIdLabels = api.refaIdLabels.concat(otherIdLabels);
            return refaIdLabels.reduce(function(prev, curr) {
                var id = curr[0];
                var label = curr[1];
                prev[id] = label;
                return prev;
            }, {})
        },

        loadContoEconomico: function(costCenter, fiscalYear, tillThisYear) {
            let paramName = tillThisYear ? "till-fiscal-year" : "fiscal-year";
            /*if (tillThisYear)
                paramName = "till-fiscal-year";*/
            var fiscalYearStart = moment(fiscalYear).format("YYYY-MM");
            return Http.get(`api/reports/conto_economico?${paramName}=${fiscalYearStart}&costCenter=${costCenter}`)
                .then(function(data) {
                    var refa = api.mastriSumsToContoEconomico(data);
                    return refa;
                });
        },

        mastriSumsToContoEconomico: function(mastriSums) {
            var refa = {
                valore_produzione: {
                    mastri: {},
                    value: 0
                },
                costi_produzione: {
                    mastri: {},
                    value: 0
                },
                margine_operativo_netto: 0,
                gestione_finanziaria: {
                    mastri: {},
                    value: 0
                },
                tributi: {
                    mastri: {},
                    value: 0
                },
                utile_netto: 0
            };
            var invertedChartOfAccounts = api.invertAggregations(api.contoEconomicoAggregations);

            for (var mastro in mastriSums) {
                var aggregationName = invertedChartOfAccounts[mastro];
                if (aggregationName) {
                    var mastroSum = mastro.startsWith("r_") ? -mastriSums[mastro] : mastriSums[mastro];
                    refa[aggregationName].mastri[mastro] = mastroSum;
                    refa[aggregationName].value += mastroSum;
                } else {
                    refa[mastro] = mastriSums[mastro];
                }
            }
            refa.margine_operativo_netto = refa.valore_produzione.value - refa.costi_produzione.value;
            refa.utile_netto = refa.margine_operativo_netto - refa.gestione_finanziaria.value - refa.tributi.value;
            return refa;
        },

        contoEconomicoAggregations: {
            valore_produzione: [
                M_CE_QUOTE_ASSOCIATIVE,
                M_CE_CONTRIBUTI_ASSOCIATIVI,
                M_CE_SERVIZI_COMMERCIALI,
                M_CE_SPONSOR,
                M_CE_DONAZIONI,
                M_CE_CONTRIBUTI_DA_ENTI,
                M_CE_RACCOLTA_FONDI,
                M_CE_ATTIVITA_COMMERCIALE,
                M_CE_IVA_FORFETTARIO
            ],
            costi_produzione: [
                M_CE_COLLABORATORI,
                M_CE_STIPENDI,
                M_CE_AFFILIAZIONI_E_TESSERAMENTI,
                M_CE_ALIMENTI_E_BEVANDE,
                M_CE_ATTREZZATURE,
                M_CE_MERCI_CONTO_ACQUISTI,
                M_CE_SERVIZI,
                M_CE_MANUTENZIONE,
                M_CE_UTENZE_ACQUA,
                M_CE_UTENZE_ELETTRICITA,
                M_CE_UTENZE_GAS,
                M_CE_UTENZE_TELEFONO,
                M_CE_UTENZE_ALTRO,
                M_CE_MATERIALE_DI_CONSUMO,
                M_CE_FITTI_PASSIVI,
                M_CE_VIAGGI_E_TRASFERIMENTI,
                M_CE_RIMBORSI_KILOMETRICI,
                M_CE_VITTO,
                M_CE_ALLOGGIO,
                M_CE_SOFTWARE,
                M_CE_RIMBORSI_PIE_DI_LISTA
            ],
            gestione_finanziaria: [
                M_CE_ONERI_BANCARI,
                M_CE_INTERESSI_ATTIVI,
                M_CE_INTERESSI_PASSIVI
            ],
            tributi: [
                M_CE_ONERI_SOCIALI,
                M_CE_IMPOSTE_E_TASSE,
            ]
        },

        invertAggregations: function(chartOfAccounts) {
            var invertedChartOfAccounts = {};
            for (var aggregationName in chartOfAccounts) {
                var aggregation = chartOfAccounts[aggregationName];
                aggregation.forEach(function(mastro) {
                    invertedChartOfAccounts[mastro] = aggregationName;
                })
            }
            return invertedChartOfAccounts;
        },

        contoEconomicoIdLabels: [
            ["valore_produzione", "Totale valore della produzione"],
            ["costi_produzione", "Totale costo della produzione"],
            ["margine_operativo_netto", "MARGINE OPERATIVO NETTO"],
            ["gestione_finanziaria", "Totale gestione finanziaria"],
            ["tributi", "Totale tributi"],
            ["utile_netto", "UTILE NETTO"],
            [M_CE_QUOTE_ASSOCIATIVE, "Quote associative"],
            [M_CE_CONTRIBUTI_ASSOCIATIVI, "Contributi associativi"],
            [M_CE_SERVIZI_COMMERCIALI, "Servizi Commerciali"],
            [M_CE_SPONSOR, "Sponsor"],
            [M_CE_DONAZIONI, "Erogazioni liberali"],
            [M_CE_CONTRIBUTI_DA_ENTI, "Contributi da enti"],
            [M_CE_RACCOLTA_FONDI, "Raccolta fondi"],
            [M_CE_ATTIVITA_COMMERCIALE, "Attività commerciale"],
            [M_CE_IVA_FORFETTARIO, "Ricavi forfettario Iva"],

            [M_CE_INTERESSI_ATTIVI, "Interessi attivi"],
            [M_CE_INTERESSI_PASSIVI, "Interessi passivi"],

            [M_CE_COLLABORATORI, "Collaboratori"],
            [M_CE_STIPENDI, "Stipendi"],
            [M_CE_ONERI_SOCIALI, "Oneri sociali"],
            [M_CE_IMPOSTE_E_TASSE, "Imposte e tasse"],
            [M_CE_ONERI_BANCARI, "Oneri bancari"],
            [M_CE_AFFILIAZIONI_E_TESSERAMENTI, "Affiliazioni e tesseramenti"],
            [M_CE_ALIMENTI_E_BEVANDE, "Alimenti e bevande"],
            [M_CE_ATTREZZATURE, "Attrezzature"],
            [M_CE_MERCI_CONTO_ACQUISTI, "Merce conto acquisti"],
            [M_CE_SERVIZI, "Servizi"],
            [M_CE_MANUTENZIONE, "Manutenzione"],
            [M_CE_UTENZE_ACQUA, "Acqua"],
            [M_CE_UTENZE_ELETTRICITA, "Elettricità"],
            [M_CE_UTENZE_GAS, "GAS"],
            [M_CE_UTENZE_TELEFONO, "Telefono"],
            [M_CE_UTENZE_ALTRO, "Altre utenze"],
            [M_CE_MATERIALE_DI_CONSUMO, "Materiali di consumo"],
            [M_CE_FITTI_PASSIVI, "Fitti passivi"],
            [M_CE_VIAGGI_E_TRASFERIMENTI, "Viaggi e trasferimenti"],
            [M_CE_RIMBORSI_KILOMETRICI, "Rimborsi kilometrici"],
            [M_CE_VITTO, "Vitto"],
            [M_CE_ALLOGGIO, "Alloggio"],
            [M_CE_SOFTWARE, "Software"],
            [M_CE_RIMBORSI_PIE_DI_LISTA, "Rimborsi piè di lista"]
        ],

        getContoEconomicoIdToLabels: function() {
            return api.contoEconomicoIdLabels.reduce(function(prev, curr) {
                var id = curr[0];
                var label = curr[1];
                prev[id] = label;
                return prev;
            }, {})
        },

        contoEconomicoToUIContoEconomico: function(refa) {
            var uiRefa = [];
            for (var i = 0; i < api.contoEconomicoAggregationsOrder.length; i++) { //var aggregationName in tmp
                var aggregationName = api.contoEconomicoAggregationsOrder[i];
                if (refa[aggregationName] === undefined)
                    continue;
                var refaAggregation = {};
                var aggregation = refa[aggregationName];
                refaAggregation.children = [];
                if (aggregation.mastri) {
                    var aggregationValue = 0;
                    for (var mastro in aggregation.mastri) {
                        var child = {
                            mastro: mastro,
                            value: aggregation.mastri[mastro],
                            label: api.getContoEconomicoIdToLabels()[mastro] ? api.getContoEconomicoIdToLabels()[mastro] : mastro
                        };
                        refaAggregation.children.push(child);
                    }
                    var aggregationValue = aggregation.value;
                } else {
                    var aggregationValue = aggregation;
                }

                refaAggregation.item = {
                    id: aggregationName,
                    label: api.getContoEconomicoIdToLabels()[aggregationName] ? api.getContoEconomicoIdToLabels()[aggregationName] : aggregationName,
                    value: aggregationValue
                }
                uiRefa.push(refaAggregation);
            }
            return uiRefa;
        },

        contoEconomicoAggregationsOrder: ["valore_produzione", "costi_produzione", "margine_operativo_netto",
            "gestione_finanziaria", "tributi", "utile_netto"
        ],

        loadStatoPatrimoniale: function(costCenter, fiscalYear) {
            var fiscalYearStart = api.dateToKey(fiscalYear);

            return $q.all([
                    api.loadContoEconomico(costCenter, fiscalYear, true),
                    api.loadContoEconomico(costCenter, fiscalYear),
                    Http.get(`api/reports/stato_patrimoniale/${fiscalYearStart}?costCenter=${costCenter}`)
                ])
                .then(function(resp) {
                    var fondoEserciziPrecedenti = resp[0].utile_netto;
                    var risultatoEsercizio = resp[1].utile_netto;
                    var mastriStatoPatrimoniale = resp[2];
                    mastriStatoPatrimoniale[M_SP_FONDO_ESERCIZI_PRECEDENTI] = fondoEserciziPrecedenti;
                    mastriStatoPatrimoniale[M_SP_RISULTATO_ESERCIZIO] = risultatoEsercizio;
                    var attivo = api.filterAttivoStatoPatrimoniale(mastriStatoPatrimoniale);
                    var passivo = api.filterPassivoStatoPatrimoniale(mastriStatoPatrimoniale);
                    var statoPatrimoniale = {
                        attivo: {
                            mastri: attivo,
                            value: api.totaleMastri(attivo)
                        },
                        passivo: {
                            mastri: passivo,
                            value: api.totaleMastri(passivo)
                        }
                    }
                    return statoPatrimoniale;
                });
        },

        dateToKey: function(date) {
            return moment(date).format("YYYY-MM");
        },

        filterAttivoStatoPatrimoniale: function(statoPatrimoniale) {
            var attivo = {};
            for (var mastro in statoPatrimoniale) {
                if (mastro.startsWith("a_"))
                    attivo[mastro] = statoPatrimoniale[mastro];
            }
            return attivo
        },

        filterPassivoStatoPatrimoniale: function(statoPatrimoniale) {
            var passivo = {};
            for (var mastro in statoPatrimoniale) {
                if (!mastro.startsWith("a_")) {
                    if (mastro.startsWith("p_"))
                        passivo[mastro] = -statoPatrimoniale[mastro];
                    else
                        passivo[mastro] = statoPatrimoniale[mastro];
                }
            }
            return passivo
        },

        totaleMastri: function(aggregation) {
            var totale = 0;
            for (var mastro in aggregation) {
                totale += aggregation[mastro];
            }
            return totale;
        },

        loadStatoPatrimonialeMastriOrderAndLabels: function() {
            return EntitiesService.load("banca")
                .then(function(banks) {
                    var bankLabels = banks.reduce(function(prev, curr) {
                        prev["a_" + curr._id] = curr.shortName;
                        return prev;
                    }, {});
                    var bankIds = banks.map(function(bank) {
                        return "a_" + bank._id;
                    });
                    return [bankLabels, bankIds];
                })
                .then(function(data) {
                    var bankLabels = data[0];
                    var bankIds = data[1];

                    var idLabels = [
                        [M_SP_CASSA, "Cassa"],
                        [M_SP_CREDITI_VERSO_CLIENTI, "Crediti verso clienti"],
                        [M_SP_CREDITI_VERSO_SOCI, "Crediti verso soci"],

                        [M_SP_RISULTATO_ESERCIZIO, "Risultato esercizio"],
                        [M_SP_FONDO_ESERCIZI_PRECEDENTI, "Fondo esercizi precedenti"],

                        [M_SP_DEBITI_VERSO_CLIENTI, "Debiti verso clienti"],
                        [M_SP_DEBITI_VERSO_SOCI, "Debiti verso soci"],
                        [M_SP_DEBITI_VERSO_FORNITORI, "Debiti verso fornitori"],
                        [M_SP_DEBITI_VERSO_COLLABORATORI, "Debiti verso collaboratori"],
                        [M_SP_PERSONALE_CONTO_RETRIBUZIONE, "Personale conto retribuzione"],
                        [M_SP_PREVIDENZA_DIPENDENTI, "Previdenza dipendenti"],
                        //[M_SP_PREVIDENZA_COLLABORATORI, "Previdenza collaboratori"],
                        [M_SP_DEBITI_STATO, "Debiti verso stato"],
                        [M_SP_DEBITI_PER_RITENUTE_DIPENDENTI, "Debiti per ritenute dipendenti"],
                        [M_SP_DEBITI_PER_RITENUTE_COLLABORATORI, "Debiti per ritenute collaboratori"],
                        [M_SP_IVA_NOSTRO_DEBITO, "Iva nostro debito"],
                        [M_SP_IVA_CONTO_LIQUIDAZIONE, "Iva conto liquidazione"],
                        [M_SP_MUTUO, "Mutuo"],
                    ];

                    var res = {
                        mastri_attivo: [M_SP_CASSA, M_SP_CREDITI_VERSO_CLIENTI, M_SP_CREDITI_VERSO_SOCI],
                        mastri_passivo: [M_SP_RISULTATO_ESERCIZIO, M_SP_FONDO_ESERCIZI_PRECEDENTI,
                            M_SP_DEBITI_VERSO_CLIENTI, M_SP_DEBITI_VERSO_SOCI, M_SP_DEBITI_VERSO_FORNITORI,
                            M_SP_DEBITI_VERSO_COLLABORATORI, M_SP_PERSONALE_CONTO_RETRIBUZIONE, M_SP_PREVIDENZA_DIPENDENTI, /*M_SP_PREVIDENZA_COLLABORATORI,*/
                            M_SP_DEBITI_STATO, M_SP_DEBITI_PER_RITENUTE_DIPENDENTI,
                            M_SP_DEBITI_PER_RITENUTE_COLLABORATORI, M_SP_IVA_NOSTRO_DEBITO, M_SP_IVA_CONTO_LIQUIDAZIONE,
                            M_SP_MUTUO
                        ],

                        idToLabels: idLabels.reduce(function(prev, curr) {
                            var id = curr[0];
                            var label = curr[1];
                            prev[id] = label;
                            return prev;
                        }, {}),
                    };
                    res.mastri_attivo = res.mastri_attivo.concat(bankIds);
                    var labels = _.assign(res.idToLabels, bankLabels);
                    return res;
                });
        },

        loadStatoPatrimonialeUI: function(costCenter, socialYear) {
            return $q.all([
                    api.loadStatoPatrimoniale(costCenter, socialYear),
                    api.loadStatoPatrimonialeMastriOrderAndLabels()
                ])
                .then(function(data) {
                    var statoPatrimoniale = data[0];
                    var ordAndLabels = data[1];

                    statoPatrimoniale.attivo = {
                        mastri: api.toUIDataStatoPatrimoniale(statoPatrimoniale.attivo.mastri, ordAndLabels.mastri_attivo, ordAndLabels.idToLabels),
                        value: statoPatrimoniale.attivo.value
                    };
                    statoPatrimoniale.passivo = {
                        mastri: api.toUIDataStatoPatrimoniale(statoPatrimoniale.passivo.mastri, ordAndLabels.mastri_passivo, ordAndLabels.idToLabels),
                        value: statoPatrimoniale.passivo.value
                    };
                    return statoPatrimoniale
                })
        },

        toUIDataStatoPatrimoniale: function(statoPatrimoniale, order, idToLabels) {
            var res = [];
            for (var i = 0; i < order.length; i++) { //var mastro in statoPatrimoniale
                var mastro = order[i];
                if (statoPatrimoniale[mastro] === undefined)
                    continue;
                var el = {
                    mastro: mastro,
                    label: idToLabels[mastro] ? idToLabels[mastro] : mastro,
                    value: statoPatrimoniale[mastro]
                };
                res.push(el);
            }
            return res;
        },

        //stato patrimoniale

        loadMastroRefa: function(costCenter, fiscalYear, mastro) {
            var fiscalYearStart = api.dateToKey(fiscalYear);
            return Http.get(`api/reports/mastri/refa/${fiscalYearStart}/${mastro}?costCenter=${costCenter}`)
                .then(function(data) {
                    var progressiveBalance = 0;
                    data.forEach(function(row) {
                        var credit = row.credit ? row.credit : 0;
                        var debt = row.debt ? row.debt : 0;
                        progressiveBalance += credit - debt;
                        row.progressiveBalance = progressiveBalance;
                    });
                    return data;
                });
        },

        loadMastroStatoPatrimoniale: function(costCenter, fiscalYear, mastro) {
            var fiscalYearStart = api.dateToKey(fiscalYear);
            return Http.get(`api/reports/mastri/stato_patrimoniale/${fiscalYearStart}/${mastro}?costCenter=${costCenter}`)
                .then(function(data) {
                    var progressiveBalance = data.openingBalance;
                    data.rows.forEach(function(row) {
                        var credit = row.credit ? row.credit : 0;
                        var debt = row.debt ? row.debt : 0;
                        progressiveBalance += credit - debt;
                        row.progressiveBalance = progressiveBalance;
                    });
                    data.fiscalYearTotal = data.total;
                    data.finalBalance = data.openingBalance + data.total;
                    return data;
                });
        },

        loadMastroContoEconomico: function(costCenter, fiscalYear, mastro) {
            var fiscalYearStart = api.dateToKey(fiscalYear);
            return Http.get(`api/reports/mastri/conto_economico/${fiscalYearStart}/${mastro}?costCenter=${costCenter}`)
                .then(function(data) {
                    var progressiveBalance = 0;
                    data.forEach(function(row) {
                        var credit = row.credit ? row.credit : 0;
                        var debt = row.debt ? row.debt : 0;
                        progressiveBalance += credit - debt;
                        row.progressiveBalance = progressiveBalance;
                    });
                    return data;
                });
        },

        loadCashFlow: function(costCenter, socialYearStart) {
            var socialYearStart = moment(socialYearStart).format("YYYY-MM");
            return Http.get(`api/reports/cash_flow?fiscal-year=${socialYearStart}&costCenter=${costCenter}`)
        },

        loadCashFlowBetweenDates: function(costCenter, startDate, endDate, bank) {
            var startDate = moment(startDate).format("YYYY-MM-DD");
            var endDate = moment(endDate).format("YYYY-MM-DD");
            return Http.get(`api/reports/cash_flow/from/${startDate}/to/${endDate}?bank=${bank}`);
        },

        loadRegistroIva: function(fiscalYearStartDate) {
            var fiscalYearStart = moment(fiscalYearStartDate).format("YYYY-MM");
            var tokens = fiscalYearStart.split("-");
            var fiscalYearEnd = (+tokens[0] + 1) + "-" + tokens[1];
            var startKey = ["registroIva", fiscalYearStart];
            var endKey = ["registroIva", fiscalYearEnd];

            var options = {
                startkey: startKey,
                endkey: endKey,
                reduce: false,
                only_values_id: true,
            };
            return EntitiesService.loadView("accounting-reports", "accountingReports", options)
                .catch(function(error) {
                    $log.error("can't loadRegistroIva: " + error);
                });
        },

        loadLiquidazioneIva: function(fiscalYearStartDate) {
            var anno = moment(fiscalYearStartDate).format("YYYY");

            var startKey = ["verificaIva", anno];
            var endKey = ["verificaIva", anno, "\uffff"];

            var options = {
                startkey: startKey,
                endkey: endKey,
                reduce: true,
                group: true, //exact grouping
                keys_on_values: true
            };
            return EntitiesService.loadView("accounting-reports", "accountingReports", options)
                .then(function(addebitiIva) {
                    addebitiIva = addebitiIva.map(function(addebitoIva) {
                        addebitoIva.toBePayed = addebitoIva.debt - addebitoIva.credit;
                        return addebitoIva;
                    });
                    return addebitiIva;
                })
        },

        getLastReceiptNumber: function(socialYearStartDate, receiptType) {
            var fiscalYearStart = moment(socialYearStartDate).format("YYYY-MM");
            return Http.get(`api/accounting/last_receipt_number/${receiptType}/${fiscalYearStart}`)
                .then(function(data) {
                    return data;
                });
        },

        getReceipts: function(receiptType, socialYearStartDate) {
            var fiscalYearStart = moment(socialYearStartDate).format("YYYY-MM");
            return Http.get("api/reports/receipts/" + receiptType + "/" + fiscalYearStart)
                .then(function(data) {
                    return data;
                });
        },

        extractInputTypeAndSubject: function(rows, field) {
            if (!field)
                field = 'description';
            var res = rows.map(function(row) {
                var tokens = row.description.split("-");
                row.inputSubject = tokens[0];
                if (tokens[0].startsWith("associate_") && tokens[0].indexOf("Input") != -1) {
                    row.inputSubject = "Socio";
                }
                row.inputType = tokens[1];
                return row;
            });
            return res;
        },

        loadIndex: function(indexName, seasonStartDate, valuesAreObjects, otherKeys) {
            var startYear = moment(seasonStartDate).year();
            var startMonth = moment(seasonStartDate).month() + 1;
            var endYear = startYear + 1;
            var endMonth = startMonth - 1;
            var startKey = ["kpi", indexName, startYear, startMonth];
            var endKey = ["kpi", indexName, endYear, endMonth];

            var options = {
                startkey: startKey,
                endkey: endKey,
                reduce: true,
                group: true, //exact grouping
                keys_on_values: true
            };
            return EntitiesService.loadView("accounting-reports", "accountingReports", options)
                .then(function(indexDataTmp) {
                    var monthlyValues = Array.apply(null, Array(12)).map(Number.prototype.valueOf, 0);
                    if (valuesAreObjects) {
                        monthlyValues = Array.apply(null, Array(12)).map(function() {
                            return {}
                        });
                    }
                    if (otherKeys) {
                        var indexData = {};
                    } else {
                        var indexData = monthlyValues;
                    }
                    indexDataTmp.forEach(function(costo) {
                        var month = costo.key[3];
                        var pos = (month - startMonth + 12) % 12;
                        if (valuesAreObjects) {
                            for (var prop in costo) {
                                if (prop != "key" && prop != "reduceType") {
                                    indexData[pos][prop] = costo[prop];
                                }
                            }
                        } else {
                            if (otherKeys) {
                                var otherKey = costo.key[4];
                                if (!indexData[otherKey])
                                    indexData[otherKey] = angular.copy(monthlyValues);
                                indexData[otherKey][pos] = costo.value;
                            } else {
                                indexData[pos] = costo.value;
                            }
                        }
                    });

                    return indexData;
                });
        },

        loadCanaleContattoCount: function(channelId) {
            var startKey = ["kpi", "CanaleContatto", channelId];
            var endKey = ["kpi", "CanaleContatto", channelId, "\uffff"];
            var options = {
                startkey: startKey,
                endkey: endKey,
                reduce: true,
                group_level: 3,
                only_values: true
            };
            return EntitiesService.loadView("accounting-reports", "accountingReports", options)
                .then(function(counts) {
                    return counts.length > 0 ? counts[0].value : 0;
                })
        },

        loadCostCenters: function() {
            return Http.get("/api/accounting/costCenters");
        }
    };

    function mastroCEToMastroRefa(nomeMastroCE) {
        var nomeMastroRefa = nomeMastroCE.replace("r_", "e_").replace("c_", "u_");
        return nomeMastroRefa
    }

    return api;
});
