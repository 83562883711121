function CmScheduleCalendarPrintController($scope, EntitiesService, MessageBox, $log, $rootScope, $location) {
    let self = this;

    this.$onInit = function() {};

    this.print = function() {
        $rootScope.$broadcast('printScheduleCalendar');
    };

    this.isPrintScheduleDisabled = function() { //shouldn't be necessary
        let searchObj = $location.search();
        let viewName = searchObj.view;
        // return $scope.currentRoomId == null;
        return viewName != "agendaDay";
    };
}

angular.module('activitiesScheduleComponents')
    .component('cmScheduleCalendarPrint', {
        templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/cm-schedule-calendar-print.template.html',
        controller: CmScheduleCalendarPrintController,
        bindings: {}
    });
