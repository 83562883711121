function CmActivityAttendanceController($scope, $element, $attrs, AssociatesDaoService, Constants, EntitiesService,
                                        UIService, uiGridConstants, MessageBox, AttendanceService,
                                        UtilsService) {
    this.$onInit = function() {
        this.associateIds = this.associates.map((associate) => associate.id);
        this.associateNamesMap = this.associates.reduce(function(acc, associate) {
            acc[associate.id] = associate.label;
            return acc;
        }, {});

        AssociatesDaoService.loadCounterparts(Constants.ROLE_COLLABORATOR)
            .then(counterparts => {
                this.counterparts = counterparts;
            });
        let attendanceIdFromCal = null;
        if (this.calEvent && this.calEvent.attendanceDocId) {
            attendanceIdFromCal = this.calEvent.attendanceDocId;
        }
        this.loadOrCreateActivityAttendance(this.selectedActivityEvent, attendanceIdFromCal)
            .then(
                attendanceDoc => {
                    this.attendanceStartDate = this.getAttendanceDate(attendanceDoc);
                    this.lessonAttendance = attendanceDoc;
                    UtilsService.checkpoint(this.lessonAttendance);
                    this.setAttendance({
                        attendance: this.lessonAttendance
                    });
                }
            )
    };

    this.getAttendanceDate = function(attendance) {
        var tokens = attendance._id.split("_");
        let date;
        if (attendance.date) {
            date = attendance.date;
        } else {
            date = tokens[3];
        }
        return moment(date).toDate();
    };

    this.loadOrCreateActivityAttendance = function(activityEvent, attendanceIdFromCal) {
        const attendanceDocId = attendanceIdFromCal ? attendanceIdFromCal : activityEvent.id.replace("_lesson", "LessonAttendance");
        return EntitiesService.get("attendance", attendanceDocId)
            .then(
                attendance => {
                    return attendance;
                },
                error => {
                    return AttendanceService.createLessonAttendance(activityEvent);
                }
            );

        /*if (!this.selectedActivityEvent) {  //activitySchedule is being created
            const firstActivity = this.activitySchedule.lessons[0];
            activityEvent = {
                activityScheduleId: this.activitySchedule._id,
                activityAppointmentId: firstActivity.id,
                startDate: firstActivity.startDate,
                endDate: firstActivity.endDate,
                instructorId: this.activitySchedule.instructorId
            };
        } */
    };

    this.onParticipantClick = function(associateId) {
        window.open(`index.html#!/inputs_associate/${associateId}_input`, 'associateInputs');
    };

    this.setParticipants = function(participants) {
        this.lessonAttendance.participants = participants;
        UtilsService.checkpoint(this.lessonAttendance);
    };

    this.deleteActivityAttendance = function() {
        MessageBox.open({
            title: "Conferma cancellazione"
        })
            .then(
                () => {
                    return this.uibModalInstance.close(
                        [{
                            action: "deleteActivityAttendance",
                            entity: this.lessonAttendance
                        }]);
                },
                function escPressed() {}
            )
    };

    this.isAttendanceInputVisible = function() {
        return !this.lessonAttendance.participants.length || this.lessonAttendance.attendance || this.showAttendanceInput;
    }
}

angular.module('activitiesScheduleComponents')
    .component('cmActivityAttendance', {
        templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/cm-activity-attendance.template.html',
        controller: CmActivityAttendanceController,
        bindings: {
            activitySchedule: '<',
            associates: '<',
            selectedActivityEvent: '<',
            calEvent: '<',
            calendarEl: '<',
            uibModalInstance: '<',
            setAttendance: '&'
        }
    });
