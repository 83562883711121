"use strict";

// var app = angular.module('app');

twaCC.factory('ImageService', function($q, $log) {

    var Base64Binary = {
        _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

        /* will return a  Uint8Array type */
        decodeArrayBuffer: function(input) {
            var bytes = (input.length / 4) * 3;
            var ab = new ArrayBuffer(bytes);
            this.decode(input, ab);

            return ab;
        },

        removePaddingChars: function(input) {
            var lkey = this._keyStr.indexOf(input.charAt(input.length - 1));
            if (lkey == 64) {
                return input.substring(0, input.length - 1);
            }
            return input;
        },

        decode: function(input, arrayBuffer) {
            //get last chars to see if are valid
            input = this.removePaddingChars(input);
            input = this.removePaddingChars(input);

            var bytes = parseInt((input.length / 4) * 3, 10);

            var uarray;
            var chr1, chr2, chr3;
            var enc1, enc2, enc3, enc4;
            var i = 0;
            var j = 0;

            if (arrayBuffer)
                uarray = new Uint8Array(arrayBuffer);
            else
                uarray = new Uint8Array(bytes);

            input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

            for (i = 0; i < bytes; i += 3) {
                //get the 3 octects in 4 ascii chars
                enc1 = this._keyStr.indexOf(input.charAt(j++));
                enc2 = this._keyStr.indexOf(input.charAt(j++));
                enc3 = this._keyStr.indexOf(input.charAt(j++));
                enc4 = this._keyStr.indexOf(input.charAt(j++));

                chr1 = (enc1 << 2) | (enc2 >> 4);
                chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                chr3 = ((enc3 & 3) << 6) | enc4;

                uarray[i] = chr1;
                if (enc3 != 64) uarray[i + 1] = chr2;
                if (enc4 != 64) uarray[i + 2] = chr3;
            }

            return uarray;
        }
    };

    var api = {
        dataURItoBlob: function(dataURI) {
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            //return new Blob([ia], {type:mimeString});
            return new File([ia], {
                type: mimeString
            });
        },

        resize: function(file, max_width, max_height, compression_ratio, imageEncoding) { //, callback
            var fileLoader = new FileReader(),
                canvas = document.createElement('canvas'),
                context = null,
                imageObj = new Image(),
                blob = null;

            //create a hidden canvas object we can use to create the new resized image data
            canvas.id = "hiddenCanvas";
            canvas.width = max_width;
            canvas.height = max_height;
            canvas.style.visibility = "hidden";
            document.body.appendChild(canvas);

            //get the context to use
            context = canvas.getContext('2d');

            // check for an image then
            //trigger the file loader to get the data from the image
            if (file.type.match('image.*')) {
                fileLoader.readAsDataURL(file);
            } else {
                alert('File is not an image');
            }

            // setup the file loader onload function
            // once the file loader has the data it passes it to the
            // image object which, once the image has loaded,
            // triggers the images onload function
            fileLoader.onload = function() {
                var data = this.result;
                imageObj.src = data;
            };

            fileLoader.onabort = function() {
                alert("The upload was aborted.");
            };

            fileLoader.onerror = function() {
                alert("An error occured while reading the file.");
            };


            var deferred = $q.defer();
            // set up the images onload function which clears the hidden canvas context,
            // draws the new image then gets the blob data from it
            imageObj.onload = function() {

                // Check for empty images
                if (this.width == 0 || this.height == 0) {
                    alert('Image is empty');
                } else {

                    context.clearRect(0, 0, max_width, max_height);
                    context.drawImage(imageObj, 0, 0, this.width, this.height, 0, 0, max_width, max_height);


                    //dataURItoBlob function available here:
                    // http://stackoverflow.com/questions/12168909/blob-from-dataurl
                    // add ')' at the end of this function SO dont allow to update it without a 6 character edit
                    blob = api.dataURItoBlob(canvas.toDataURL(imageEncoding));
                    blob.blobUrl = URL.createObjectURL(blob);

                    //pass this blob to your upload function
                    //$scope.updatePhoto(blob);
                    $log.debug("img resized");
                    //deferred.resolve(callback(blob));
                    deferred.resolve(blob);
                }
            };

            imageObj.onabort = function() {
                alert("Image load was aborted.");
            };

            imageObj.onerror = function() {
                alert("An error occured while loading image.");
            };
            return deferred.promise
                .then(function(blob) {
                    var arrayBuffer;
                    var fileReader = new FileReader();
                    var deferredArrayBuffer = $q.defer();
                    fileReader.onload = function() {
                        arrayBuffer = this.result;
                        deferredArrayBuffer.resolve(arrayBuffer);
                        var i = 0;
                    };
                    fileReader.readAsArrayBuffer(blob);
                    return deferredArrayBuffer.promise;
                });
        },

        /**
         * Convert an image
         * to a base64 url
         * @param  {String}   url
         * @param  {Function} callback
         * @param  {String}   [outputFormat=image/png]
         */
        convertImgToBase64URL: function(url, callback, outputFormat) {
            var img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = function() {
                var canvas = document.createElement('CANVAS'),
                    ctx = canvas.getContext('2d'),
                    dataURL;
                canvas.height = this.height;
                canvas.width = this.width;
                ctx.drawImage(this, 0, 0);
                dataURL = canvas.toDataURL(outputFormat);
                callback(dataURL);
                canvas = null;
            };
            img.src = url;
        },

        arrayBufferToBase64: function(buffer) {
            var binary = '';
            var bytes = new Uint8Array(buffer);
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        },

        dataUrlToArrayBuffer: function(dataUrl) {
            var dataBase64 = dataUrl.substring(dataUrl.indexOf(",") + 1);
            //var uintArray = Base64Binary.decode(base64_string);
            var byteArray = Base64Binary.decodeArrayBuffer(dataBase64);
            return byteArray;
        }
    };

    return api;
});
