function CmActivityParticipantsController($scope, $element, $attrs, UIService, uiGridConstants, AssociatesDaoService,
    Constants) {

    CmAssociateGridController.call(this, $scope, $element, $attrs, UIService, uiGridConstants, AssociatesDaoService,
        Constants);

    this.createColumnDefs = () => {
        const columnDefs = [{
                field: uiGridConstants.ENTITY_BINDING,
                name: "Nome",
                //width: 190,
                cellTemplate: '<div class="ui-grid-cell-contents"><a href="" ' +
                    'ng-click="grid.appScope.itemClick(grid.getCellValue(row, col))">' +
                    '{{grid.appScope.getItemName(grid.getCellValue(row, col))}}</a></div>'
            },
            {
                field: uiGridConstants.ENTITY_BINDING,
                name: "Ricevuta",
                width: 100,
                cellTemplate: '<div class="ui-grid-cell-contents"><a href="" class="glyphicon glyphicon-euro"' +
                    'ng-click="grid.appScope.createReceipt(grid.getCellValue(row, col))">' +
                    '</a></div>'
            }
        ];
        return columnDefs;
    };

    this.createReceipt = function(associateId) {
        this.onCreateReceiptClick({
            associateId: associateId
        })
    }
}

angular.module('activitiesScheduleComponents')
    .component('cmActivityParticipants', {
        templateUrl: 'components/form-components/cm-associate-grid.template.html',
        controller: CmActivityParticipantsController,
        bindings: {
            items: '<',
            setItems: '&',
            itemIdsList: '<',
            itemNamesMap: '<',
            itemsAreClickable: '<',
            onItemClick: '&',
            onCreateReceiptClick: '&',
            width: '<'
        }
    });
