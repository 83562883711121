if (typeof window == "undefined") {
    var moment = require("moment");
    var _ = require('lodash');
}

var api = {

    /*
        NOTE: Id of weekly scheduled activity attendance is invariant to activity datetime chenges within the week
     */
    createLessonAttendance: function(activityEvent) {
        var courseSchedule_id = activityEvent.activityScheduleId;
        var tokens = activityEvent.activityAppointmentId.split("_");
        var id = tokens[1];
        var duration = moment.duration(moment(activityEvent.endDate).diff(activityEvent.startDate));
        var durationHours = duration.asHours();
        var attendanceStartMoment = moment(activityEvent.startDate);
        return {
            _id: courseSchedule_id + "LessonAttendance" + "_" + id + "_" + attendanceStartMoment.format("YYYY_w"),
            week: attendanceStartMoment.week(),
            date: attendanceStartMoment.toJSON(),
            attendance: 0,
            instructorId: activityEvent.instructorId,
            durationHours: durationHours,
            participants: []
        }
    }
};

if (typeof window == "undefined") {
    module.exports = api;
} else {
    if (!window.shared) {
        window.shared = {};
    }
    window.shared.AttendanceShared = api;
}
