"use strict";

twaCC.factory('Http', function($log, $http, $location) {
    var api = {

        get: function(url, config) {
            return $http.get(url, config)
                .then(function(resp) {
                    return resp.data;
                })
                .catch(function(error) {
                    api.showLoginPageIfUnauthorized(error);
                    throw error.data;
                })
        },

        showLoginPageIfUnauthorized: function(error) {
            if (error.status == 401) {
                $location.path("/login");
            }
        },

        /*
            A put can only modify the _rev attribute of the entity
         */
        put: function(url, entity, config) {
            return $http.put(url, entity, config)
                /*.then(function(resp) {
                    var ack = resp.data;
                    entity._rev = ack.rev;
                    return resp;
                })*/
                .catch(function(error) {
                    api.showLoginPageIfUnauthorized(error);
                    throw error.data;
                });
        },

        /*
            A post can make any modification to the entity
         */
        post: function(url, entity, config) {
            return $http.post(url, entity, config)
                .then(function(resp) {
                    var respData = resp.data;
                    if (!(respData instanceof Blob) && _.isObject(entity) && !_.isArray(respData)) {
                        angular.copy(respData, entity);
                        return entity;
                    }
                    return respData;
                })
                .catch(function(error) {
                    api.showLoginPageIfUnauthorized(error);
                    throw error.data;
                });
        },

        delete: function(url, config) {
            return $http.delete(url, config)
                .then(function(resp) {
                    return resp.data;
                })
                .catch(function(error) {
                    api.showLoginPageIfUnauthorized(error);
                    throw error.data;
                });
        },

        deleteBatch: function(url, entities, config) {
            return $http.post(url, entities, config)
                .then(function(resp) {
                    return resp.data;
                })
                .catch(function(error) {
                    api.showLoginPageIfUnauthorized(error);
                    throw error.data;
                });
        }

    };

    return api;
});
