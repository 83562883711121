if (typeof window == "undefined") {
    var moment = require("moment");
    var _ = require('lodash');
}

var api = {
    isEntranceSubscription: function(subscription) {
        return (subscription != null && subscription.remainingEntrances !== null && subscription.remainingEntrances !== undefined);
    },

    filterPurchases: function(purchases, inputType) {
        var inputPrefix = _.camelCase(inputType);
        return purchases.filter(function(purchase) {
            return purchase._id.indexOf(inputPrefix) != -1
        })
    },

    sortEntranceList: function(entranceList) {
        entranceList.sort(function(a, b) {
            if (!a) return 1;
            else if (!b) return -1;
            else return b.getTime() - a.getTime();
        })
    },

    updateEntranceSubscriptionExpirationDate: function(subscription) {
        if (subscription.remainingEntrances === 0) {
            var entrancesFinishedDate = moment().endOf("day");
            var dayAfterSubscriptionStart = moment(subscription.date).add(1, "day").startOf("day");
            var expirationDate;
            if (subscription.entranceList) {
                expirationDate = subscription.entranceList.reduce(
                    function(acc, curr) {
                        var m = moment(curr);
                        if (m.isAfter(acc)) {
                            acc = m;
                        }
                        return acc;
                    },
                    moment(0)
                );
            } else {
                expirationDate = entrancesFinishedDate.isAfter(dayAfterSubscriptionStart) ? entrancesFinishedDate :
                    dayAfterSubscriptionStart;
            }
            subscription.expirationDate = expirationDate.toDate();
        } else {
            var yearAfterSubscriptionStart = moment(subscription.date).add(1, "year").startOf("day");
            subscription.expirationDate = yearAfterSubscriptionStart.toDate();
        }
    },

    sortPurchases: function(purchases) {
        purchases.sort(function(a, b) {
            if (!a.date) return 1;
            else if (!b.date) return -1;
            else return b.date.getTime() - a.date.getTime();
        })
    },

    setDateExpirationDateOnEventSubscription: function(purchase) {
        const date = _.min(purchase.dates.map(el => el.startDate));
        const expirationDate = _.max(purchase.dates.map(el => el.endDate));
        purchase.date = moment(date).startOf("day").toDate();
        purchase.expirationDate = moment(expirationDate).endOf("day").toDate();
    },

    _idPattern: /^(associate_\d+)Input-(\w+)_\d+$/,

    getAssociateIdFromId: function(_id) {
        const match = api._idPattern.exec(_id);
        const associateId = match[1];
        return associateId;
    },

    getInputTypeFromId: function(_id) {
        const match = api._idPattern.exec(_id);
        const inputTypeCc = match[2];
        const inputType = _.snakeCase(inputTypeCc);
        return inputType;
    }
};

if (typeof window == "undefined") {
    module.exports = api;
} else {
    if (!window.shared) {
        window.shared = {};
    }
    window.shared.AssociatesPurchasesShared = api;
}
