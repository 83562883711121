function CmActivityScheduleCalendarRoomsController($scope, EntitiesService, MessageBox, $log, $location, $routeParams,
    $rootScope, ActivitiesSchedulesService) {
    let self = this;

    this.$onInit = function() {
        self.currentRoomId = $routeParams.resource ? $routeParams.resource : self.resources[0]._id;
        self.initialized = false;
    };

    $scope.$on('scheduleCalendarBeforeEventsRender', (event, calendarView) => {
        if (!self.initialized) {
            self.initialized = true;
            self.changeRoom(self.currentRoomId);
        }
    });

    this.changeRoom = function(roomId) {
        $location.search('resource', roomId);

        $rootScope.$broadcast('drawCalendarEvents', {
            resourceId: roomId,
            load: true
        });
    };

    this.editSchedulingResources = function() {
        let schedulingResourcesDoc;
        return ActivitiesSchedulesService.loadSchedulingResources()
            .then(function(schedulingResources) {
                schedulingResourcesDoc = schedulingResources;
                let message = {
                    resources: schedulingResources.resources
                };
                return MessageBox.open({
                    title: 'Crea / modifica Risorse',
                    message: message,
                    templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/edit-scheduling-resources.html',
                    size: 'lg',
                    controller: "SchedulingResourcesModalController"
                });
            })
            .then(
                function(resources) {
                    schedulingResourcesDoc.resources = resources;
                    return EntitiesService.save(schedulingResourcesDoc);
                },
                function pressedEscape() {}
            )
            .then(function() {
                self.resources = schedulingResourcesDoc.resources;

                $rootScope.$broadcast('drawCalendarResources', self.resources);
                // $('#courses-calendar').fullCalendar('refetchResources');

                let roomIds = self.resources.map(function(room) {
                    return room._id
                });
                if (roomIds.indexOf(self.currentRoomId) == -1) {
                    self.currentRoomId = roomIds[0];
                    self.changeRoom(self.currentRoomId);
                }
            })
            .catch(function(e) {
                AlertService.setAlert({
                    type: "warning",
                    msg: e.description || e
                });
                $log.warn("dismissed: " + e)
            });
    };

}

angular.module('activitiesScheduleComponents')
    .component('cmActivityScheduleCalendarRooms', {
        templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/cm-activity-schedule-calendar-rooms.template.html',
        controller: CmActivityScheduleCalendarRoomsController,
        bindings: {
            resources: '<'
        }
    });
