asc.controller('CalendarScheduleModalController', function($scope, title, message, $uibModalInstance, MessageBox,
                                                           UtilsService) {
    let self = this;
    //if message is an object, put all its properties in $scope
    $scope.title = title;
    if (angular.isObject(message)) {
        angular.extend($scope, message);
    } else {
        $scope.message = message;
    }

    $scope.uibModalInstance = $uibModalInstance;

    $scope.isDeleteScheduleDisabled = function() {
        return $scope.isCreatingSchedule
    };

    $scope.deleteEvents = function() {
        return MessageBox.open({
            templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/cm-schedule-calendar-move-appointment.modal.html',
            title: "Salva",
            message: {
                getResult: function(scope) {
                    const serializableCalEvent = Object.assign({}, $scope.calEvent); //{...calEvent};
                    serializableCalEvent.start = UtilsService.ambiguousToLocalMoment(moment(serializableCalEvent.start));
                    serializableCalEvent.end = UtilsService.ambiguousToLocalMoment(moment(serializableCalEvent.end));
                    delete serializableCalEvent.source;

                    return {
                        schedule: $scope.courseSchedule,
                        appointmentId: $scope.selectedActivityEvent.activityAppointmentId,
                        calEvent: serializableCalEvent,
                        selectedEvents: scope.selectedEvents
                    }
                }
            }
        })
            .then(
                (data) => {
                    data.action = "deleteEvents";

                    return $uibModalInstance.close(
                        [data]);
                },
                function escPressed() {}
            );
    };

    $scope.deleteSchedule = function() {
        MessageBox.open({
            title: "Conferma cancellazione"
        })
            .then(
                () => {
                    return $uibModalInstance.close(
                        [{
                            action: "deleteSchedule",
                            entity: $scope.courseSchedule
                        }]);
                },
                function escPressed() {}
            )
    };

    $scope.cancelSchedule = function() {
        MessageBox.open({
            title: "Conferma disdetta"
        })
            .then(
                () => {
                    return $uibModalInstance.close(
                        [{
                            action: "cancelSchedule",
                            entity: $scope.courseSchedule
                        }]);
                },
                function escPressed() {}
            )
    };

    $scope.ok = function(form) {
        $scope.$broadcast('show-errors-check-validity');
        if (form.$invalid) {
            var errors = form.$error;
            return;
        }
        const scheduleModified = UtilsService.wasModifiedAfterCheckpoint($scope.courseSchedule);
        const actions = [];
        if (scheduleModified) {
            actions.push({
                action: "saveSchedule",
                entity: $scope.courseSchedule
            });
        }
        $scope.beforeClose(actions);
        return this.uibModalInstance.close(actions);
    };

    $scope.beforeClose = function(actions){
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };
});