function CmScheduleDatesController($scope, $element, $attrs, CalendarSchedulesService, Constants,
    UIService, uiGridConstants) {

    this.SCHEDULING_TYPE_APPOINTMENT = Constants.SCHEDULING_TYPE_APPOINTMENT;
    this.SCHEDULING_TYPE_APPOINTMENTS_LIST = Constants.SCHEDULING_TYPE_APPOINTMENTS_LIST;
    this.SCHEDULING_TYPE_WEEKLY_APPOINTMENTS = Constants.SCHEDULING_TYPE_WEEKLY_APPOINTMENTS;
    this.schedulingTypes = [{
            id: Constants.SCHEDULING_TYPE_APPOINTMENT,
            value: "Appuntamento"
        },
        /*{
            id: Constants.SCHEDULING_TYPE_APPOINTMENTS_LIST,
            value: "Lista Appuntamenti"
        },*/
        {
            id: Constants.SCHEDULING_TYPE_WEEKLY_APPOINTMENTS,
            value: "Appuntamenti Settimanali"
        },
    ];

    this.$onInit = function() {
        if (!this.activitySchedule.schedulingType) {
            this.activitySchedule.schedulingType = Constants.SCHEDULING_TYPE_APPOINTMENT;
            if (this.activitySchedule.repetition) {
                if (this.activitySchedule.repetition == Constants.REPETITION_WEEKLY) {
                    this.activitySchedule.schedulingType = Constants.SCHEDULING_TYPE_WEEKLY_APPOINTMENTS;
                } else if (this.activitySchedule.lessons.length = 1) {
                    this.activitySchedule.schedulingType = Constants.SCHEDULING_TYPE_APPOINTMENT;
                } else {
                    this.activitySchedule.schedulingType = Constants.SCHEDULING_TYPE_APPOINTMENTS_LIST;
                }
            }
        }
        delete this.activitySchedule.repetition;
        this.isSchedulingTypeChangeDisabled = (this.activityEvent && this.activityEvent.activityScheduleHasAttendance);
    };


    this.onSchedulingTypeChanged = function() {
        CalendarSchedulesService.compactActivityScheduleDates(this.activitySchedule);
        if (this.activitySchedule.schedulingType == Constants.SCHEDULING_TYPE_APPOINTMENT) {
            this.activitySchedule.lessons.length = 1;
        } else if (this.activitySchedule.schedulingType == Constants.SCHEDULING_TYPE_WEEKLY_APPOINTMENTS) {
            CalendarSchedulesService.compactLessons(this.activitySchedule);
        }
    };

    this.onAppointmentsChanged = function() {
        CalendarSchedulesService.compactActivityScheduleDates(this.activitySchedule);
    };

    this.onStartDateChanged = function(currValue) {
        if (currValue) {
            if (this.activitySchedule.schedulingType == Constants.SCHEDULING_TYPE_WEEKLY_APPOINTMENTS) {
                CalendarSchedulesService.compactLessons(this.activitySchedule);
            }
            CalendarSchedulesService.compactActivityScheduleDates(this.activitySchedule);
        }
    };

    this.onEndDateChanged = function(currValue) {
        if (currValue) {
            CalendarSchedulesService.compactActivityScheduleDates(this.activitySchedule);
        }
    };

    this.courseStartDateIsBeforeFirstAttendance = function(activityScheduleStartDate) {
        if (this.activityEvent && this.activityEvent.activityScheduleAttendanceRange) {
            const attendanceRange = this.activityEvent.activityScheduleAttendanceRange;
            const res = moment(activityScheduleStartDate).isSameOrBefore(attendanceRange.firstAttendanceDate, 'day');
            return res;
        }
        return true;
    };

    this.courseEndDateIsAfterLastAttendance = function(activityScheduleEndDate) {
        if (this.activityEvent && this.activityEvent.activityScheduleAttendanceRange) {
            const attendanceRange = this.activityEvent.activityScheduleAttendanceRange;
            const res = moment(activityScheduleEndDate).isSameOrAfter(attendanceRange.lastAttendanceDate, 'day');
            return res;
        }
        return true;
    };

    /*this.appointmentsAreNotHidden = function(activityStartDate, activityEndDate) {
        let res = CalendarSchedulesService.areAppointmentDatesAllVisible(activityStartDate, activityEndDate,
            this.activitySchedule);
        return res;
    };*/

}

angular.module('activitiesScheduleComponents')
    .component('cmScheduleDates', {
        templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/cm-schedule-dates.template.html',
        controller: CmScheduleDatesController,
        bindings: {
            activityEvent: '<',
            activitySchedule: '<',
            appointment: '<',
            handleRepetitions: '<'
        }
    });
