function CmScheduleCalendarController($scope, $element, $attrs, Constants, CalendarSchedulesService, Http, $q,
    $location, $log, $timeout, UtilsService, MessageBox, EntitiesService, $routeParams, $rootScope, $uibModalInstance) {
    let self = this;

    /*this.$onInit = function () {
        let calendarOptions = buildCalendarOptions();
        init(calendarOptions)
    };*/

    this.INITIAL_RESOURCE_ID = null; //[null, 'NOT_EXISTING_RESOURCE']


    $scope.$on('drawCalendarEvents', (event, opts) => {
        if (opts.resourceId) {
            self.priv.resourceId = opts.resourceId;
        }
        if (opts.backgroundResourceId) {
            self.priv.backgroundResourceId = opts.backgroundResourceId;
        }
        drawCalendarEvents(opts)
    });

    $scope.$on('drawCalendarResources', (event, resources) => {
        self.resources = resources;
        $('#courses-calendar').fullCalendar('refetchResources');
    });

    $scope.$on('addSchedule', (event, data) => {
        openScheduleDialog(true, false);
    });

    this.buildCalendarOptions = function() {
        return {
            height: "auto", //"parent", //"auto",
            editable: true,
            defaultView: $routeParams.view ? $routeParams.view : self.VIEWS_NAME[0],
            defaultDate: $routeParams.date,
            weekNumbers: true,
            minTime: '07:00:00',
            maxTime: '23:00:00',
            snapDuration: '00:15:00',
            header: {
                left: '',
                center: 'title',
                right: `prev,next,today ${self.VIEWS_NAME[0]},${self.VIEWS_NAME[1]},${self.VIEWS_NAME[2]}`
            },
            resourceLabelText: 'Terapisti',
            views: {
                month: {
                    titleFormat: "MMMM YYYY",
                },
                week: {
                    //columnFormat: "dddd d",
                },
                day: {
                    titleFormat: "dddd D MMMM YYYY",
                    //columnFormat: "dddd d",
                }
            },
            // eventMouseover: onEventMouseover,

            eventClick: onEventClick,
            eventResizeStart: onEventResizeStart,
            eventResizeStop: onEventResizeStop,
            eventResize: onEventResize,
            eventDragStart: onEventDragStart,
            eventDragStop: onEventDragStop,
            eventDrop: onEventDrop,

            eventAllow: this.eventAllow,
            //
            // eventTextColor: 'black',
            eventRender: self._eventRender,
            eventAfterRender: self._eventAfterRender,
            viewRender: this._viewRender,
            navLinks: true, // can click day/week names to navigate views
            eventLimit: true, // allow "more" link when too many events
            lazyFetching: false,

            events: _drawCalendarEvents, //events
            resourceAreaWidth: "25%",
            resources: self._drawCalendarResources,
        }
    };

    this.init = function(calendarOptions) {
        let collaboratorId = $location.search().collaborator;
        self.priv = {
            events: [],
            calEvents: [],
            resourceId: self.INITIAL_RESOURCE_ID,
            backgroundResourceId: collaboratorId
        };
        self._drawCalendarResources();
        $(document)
            .ready(function() {
                $('#courses-calendar')
                    .fullCalendar(calendarOptions);
            });
    };

    function onEventClick(calEvent, jsEvent, view) {
        $log.debug("event click");
        selectEvent(calEvent);
        drawCalendarEvents();
        editSchedule();
    }

    function selectEvent(calEvent) {
        selectEventById(calEvent.id);
    }

    function selectEventById(calEventId) {
        //after a move/resize calEvent changes
        self.selectedCalEvent = getCalEvent(calEventId);
        self.selectedEvent = getEvent(calEventId);

        $log.info("selectedEvent: " + JSON.stringify(self.selectedEvent));
    }

    function getCalEvent(id) {
        let res = self.priv.calEvents.filter(function(ev) {
            return id == ev.id;
        });
        return res[0];
    }

    function getEvent(id) {
        let res = self.priv.events.filter(function(ev) {
            return id == ev.id;
        });
        return res[0];
    }

    function onEventResizeStart(calEvent, jsEvent, ui, view) {
        $log.debug("resize start");
        selectEvent(calEvent);
        /*return getSelectedSchedule()
            .then(function(selectedActivitySchedule) {
                self.selectedActivitySchedule = selectedActivitySchedule;
            });*/
    }

    function getSelectedSchedule() {
        return EntitiesService.get("Schedule", self.selectedEvent.activityScheduleId);
    }

    let eventResizeStopPromise = null;

    function onEventResizeStop(calEvent, jsEvent, ui, view) {
        //$log.debug("resize stop");
        //ensure the dragged event is highlighted even if drag isn't allowed
        eventResizeStopPromise = $timeout(drawCalendarEvents, 100);
    }

    //TODO: should open the same modal as move
    function onEventResize(calEvent, delta, revertFunc, jsEvent, ui, view) {
        if (eventResizeStopPromise) {
            $timeout.cancel(eventResizeStopPromise);
        }
        return getSelectedSchedule()
            .then(function(selectedSchedule) {
                let selectedAppointment =
                    getSelectedAppointment(selectedSchedule);
                CalendarSchedulesService.resizeLesson(
                    selectedSchedule, selectedAppointment, delta);
                return EntitiesService.save(selectedSchedule);
            })
            .then(
                function() { //TODO: optimize: modify event locally
                    return drawCalendarEvents({
                        load: true
                    });
                },
                function error() {
                    revertFunc();
                }
            );
    }

    function getSelectedAppointment(schedule) {
        return getAppointment(schedule,
            self.selectedEvent.activityAppointmentId); //TODO: rename activityAppointmentId in backend
    }

    //TODO: find different name, ex ScheduleItem
    function getAppointment(schedule, appointmentId) {
        let res = schedule.lessons.filter(function(app) {
            return appointmentId == app.id;
        });
        return res[0];
    }

    function onEventDragStart(calEvent, jsEvent, ui, view) {
        $log.debug("drag start");
        selectEvent(calEvent);
    }

    let dragStopPromise = null;

    function onEventDragStop(calEvent, jsEvent, ui, view) {
        //ensure the moved event is highlighted even if move isn't allowed
        dragStopPromise = $timeout($scope.drawCalendarEvents, 100);
    }

    function onEventDrop(calEvent, delta, revertFunc, jsEvent, ui, view) {
        if (dragStopPromise) {
            $timeout.cancel(dragStopPromise);
        }
        return getSelectedSchedule()
            .then(function(selectedSchedule) {
                let selectedAppointment =
                    getSelectedAppointment(selectedSchedule);
                const serializableCalEvent = Object.assign({}, calEvent); //{...calEvent};
                serializableCalEvent.start = UtilsService.ambiguousToLocalMoment(serializableCalEvent.start);
                serializableCalEvent.end = UtilsService.ambiguousToLocalMoment(serializableCalEvent.end);
                delete serializableCalEvent.source;
                if (selectedSchedule.schedulingType == Constants.SCHEDULING_TYPE_WEEKLY_APPOINTMENTS) {
                    return MessageBox.open({
                            templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/cm-schedule-calendar-move-appointment.modal.html',
                            title: "Salva",
                            message: {
                                getResult: function(scope) {
                                    return scope.selectedEvents
                                }
                            }
                        })
                        .then(
                            (selectedEvents) => {
                                return CalendarSchedulesService.moveLesson(selectedSchedule, selectedAppointment,
                                    delta, serializableCalEvent, self.SCHEDULE_FIELD_IDENTIFYING_RESOURCE, calEvent.resourceId, selectedEvents);
                            },
                            function escPressed() {}
                        );
                } else {
                    return CalendarSchedulesService.moveLesson(selectedSchedule, selectedAppointment,
                        delta, serializableCalEvent, self.SCHEDULE_FIELD_IDENTIFYING_RESOURCE, calEvent.resourceId);
                }
            })
            .then(
                function() { //TODO: optimize: modify Event locally
                    return drawCalendarEvents({
                        load: true
                    });
                },
                function(e) {
                    AlertService.setAlert({
                        type: "danger",
                        msg: e.description || e
                    });
                    return drawCalendarEvents();
                }
            );
    }

    this.eventAllow = function(dropInfo, draggedEvent) {
        let activityEvent = self.selectedEvent;
        let allowed = true;

        //interpret ambiguously zoned date (no tz info) as date in local tz
        let eventStart = moment(dropInfo.start.format());
        //$log.debug("dropDate: "+eventDropDate.format());
        const isWeeklyRepetition = activityEvent.repetition == Constants.REPETITION_WEEKLY ||
            activityEvent.schedulingType == Constants.SCHEDULING_TYPE_WEEKLY_APPOINTMENTS;

        allowed = CalendarSchedulesService.isAppointmentDateVisibleAfterMove(eventStart,
            activityEvent.scheduleStartDate, activityEvent.scheduleEndDate, isWeeklyRepetition);
        return allowed;
    };

    this._eventRender = function(event, element, view) {

    };

    this._eventAfterRender = function(event, element, view) {

    };

    let viewRenderPromise = null;
    this._viewRender = function(view, element) {

        if (viewRenderPromise) {
            $timeout.cancel(viewRenderPromise);
        }
        viewRenderPromise = $timeout(function() {
            $rootScope.$broadcast('scheduleCalendarBeforeEventsRender', view);
            viewRenderPromise = null;
        }, 500);
    };

    let loadEventsFromServer = true;
    let loadEventsDeferred = null;

    function drawCalendarEvents(opts) {
        loadEventsFromServer = false;
        if (opts) {
            loadEventsFromServer = opts.load;
        }
        $('#courses-calendar')
            .fullCalendar('refetchEvents');
        loadEventsFromServer = true;
        if (opts && opts.load) {
            loadEventsDeferred = $q.defer();
            return loadEventsDeferred.promise;
        } else {
            return $q.when(null);
        }
    }
    this.drawCalendarEvents = drawCalendarEvents;

    let drawCalendarPromise = null;

    function _drawCalendarEvents(start, end, timezone, callback) {

        let date = $('#courses-calendar')
            .fullCalendar('getDate');
        $location.search('date', date.format()
            .substring(0, 10));

        let daysSpan = end.diff(start, 'days');
        let view = self.VIEWS_NAME[2];
        if (daysSpan == 1) {
            view = self.VIEWS_NAME[0];
        } else if (daysSpan == 7) {
            view = self.VIEWS_NAME[1];
        }
        $location.search('view', view);

        $log.info(`_drawCalendarEvents(${start.format()
            .substring(0, 10)}, ${end.format()
            .substring(0, 10)})`);
        self.removeTooltips();

        let opts = {
            load: loadEventsFromServer
        };

        /*
            Since we are using fullcalendar in timezone = false mode
            https://fullcalendar.io/docs/timezone/timezone/

            dates are unbiguously-zoned and are interpreted UTC
            moment(start.format())  makes start be interpreted as local date
         */
        let startDate = moment(start.format())
            .toDate();
        let endDate = moment(end.format())
            .subtract(1, 'day')
            .endOf('day')
            .toDate();

        if (drawCalendarPromise) {
            $timeout.cancel(drawCalendarPromise);
        }
        drawCalendarPromise = $timeout(function() {
            doDrawCalendar();
            drawCalendarPromise = null;
        }, 250);

        function doDrawCalendar() {
            fetchCalendarEvents(startDate, endDate,
                    self.selectedEvent, opts) //$scope.eventsSource, $('#courses-calendar')
                .then(function(calEvents) {
                    $log.info(`events loaded (${start.format()
                        .substring(0, 10)}, ${end.format()
                        .substring(0, 10)}) size: ${calEvents.length}`);
                    callback(calEvents);
                    if (self.selectedEvent) {
                        selectEventById(self.selectedEvent.id);
                    }
                    if (loadEventsDeferred) {
                        loadEventsDeferred.resolve(null);
                    }
                });
        }
    }

    this.removeTooltips = function() {

    };

    function fetchCalendarEvents(start, end, selectedEvent, opts) { //eventsSource, calendarEl
        if (self.priv.resourceId == 'NOT_EXISTING_RESOURCE') {
            return $q.when([]);
        } else {
            let daysSpan = moment(end).diff(start, 'days');
            if (opts && opts.load) {
                let resourceId = self.priv.resourceId;
                if (moment(end).diff(start, 'days') == 0) {
                    resourceId = null;
                }
                return loadEvents(start, end, resourceId)
                    .then(function(events) {
                        self.priv.events = events;
                        let calEvents = buildCalEvents(events, selectedEvent, daysSpan);
                        self.priv.calEvents = calEvents;
                        return calEvents;
                    });
            } else {
                let calEvents = buildCalEvents(self.priv.events, selectedEvent, daysSpan);
                self.priv.calEvents = calEvents;
                return $q.when(calEvents);
            }
        }
    }

    function buildCalEvents(events, selectedEvent, daysSpan) {
        let visibleEvents = events.filter(function(ev) {
            return ev.id.indexOf("hidden_") != 0 && ev.id.indexOf("background_") != 0;
        });
        let backgroundEvents = events.filter(function(ev) {
            return ev.id.indexOf("background_") == 0 && self.backgroundEventsFilter(ev);
        });
        let backgroundCalEvents = buildBackgroundCalEvents(backgroundEvents, daysSpan);
        let foregroundCalEvents = visibleEvents.map(function(ev) {
            let start = moment(ev.startDate)
                .format();
            let end = moment(ev.endDate)
                .format();
            if (ev.allDay) {
                start = start.substring(0, 10);
                end = end.substring(0, 10);
            }
            let event = {
                id: ev.id,
                start: start,
                end: end,
                allDay: ev.allDay,
                resourceId: ev[self.SCHEDULE_FIELD_IDENTIFYING_RESOURCE],
                scheduleStartDate: ev.scheduleStartDate,
                scheduleEndDate: ev.scheduleEndDate,
                className: []
            };
            self.decorateCalEvent(event, ev);
            if (selectedEvent) {
                if (selectedEvent.id == ev.id) {
                    event.className.push('fc-event-selected');
                } else if (selectedEvent.activityScheduleId == ev.activityScheduleId) {
                    event.className.push('fc-schedule-selected');
                }
            }
            return event;
        });
        return foregroundCalEvents.concat(backgroundCalEvents);
    }

    this.backgroundEventsFilter = function(backgroundEvent) {
        return true;
    };

    function buildBackgroundCalEvents(backgroundEvents, daysSpan) {
        let backgroundCalEvents = backgroundEvents.map(function(ev) {
            let start = moment(ev.startDate)
                .format();
            let end = moment(ev.endDate)
                .format();
            if (ev.allDay) {
                start = start.substring(0, 10);
                end = end.substring(0, 10);
            } else if (daysSpan > 7) {
                start = start.substring(0, 10);
                end = moment(ev.endDate).add(1, 'days').format().substring(0, 10);
            }
            let event = {
                id: ev.id,
                start: start,
                end: end,
                rendering: 'background'
            };
            return event;
        });
        return backgroundCalEvents;
    }

    this.decorateCalEvent = function(calEvent, event) {

    };

    function loadEvents(fromDate, toDate, resourceId) {
        return Http.get(self.LOAD_EVENTS_URL_PREFIX + "/from/" + UtilsService.dateFormatISOLocalTime(fromDate) +
            "/to/" + UtilsService.dateFormatISOLocalTime(toDate), {
                params: {
                    resourceId: resourceId
                }
            });
    }

    this._drawCalendarResources = function(callback) {
    };

    function editSchedule() {
        openScheduleDialog(false, !self.canModifySchedule())
    }

    this.canModifySchedule = function() {
        return true;
    };

    function openScheduleDialog(isCreatingSchedule, isEditDisabled) {
        function getOrCreateSchedule() {
            if (isCreatingSchedule) {
                return $q.when(createEmptySchedule());
            } else {
                return getSelectedSchedule();
            }
        }

        getOrCreateSchedule()
            .then(function(schedule) {
                var appointment = null;
                if (!isCreatingSchedule) {
                    appointment = getSelectedAppointment(schedule);
                }
                let message = self.buildModalMessage(isCreatingSchedule, schedule, appointment, isEditDisabled);
                const modalWindowPromise = MessageBox.open({
                    title: 'Appuntamenti',
                    message: message,
                    templateUrl: self.MODAL_TEMPLATE_URL,
                    size: 'lg',
                    controller: self.MODAL_CONTROLLER,
                    scope: $scope
                });
                self.removeTooltips();
                return modalWindowPromise;
            })
            .then(
                function(commands) {
                    const promises = [];
                    for (let command of commands) {
                        if (self[command.action+"Action"]) {
                            let promise = self[command.action + "Action"](command, isCreatingSchedule);
                            promises.push(promise);
                        }
                    }
                    return $q.all(promises);
                },
                function pressedEscape() {}
            )
            .catch(function(e) {
                AlertService.setAlert({
                    type: "warning",
                    msg: e.description || e
                });
                $log.warn("dismissed: " + e)
            });
    }

    this.saveScheduleAction = function(command, isCreatingSchedule){
        const schedule = command.entity;
        const promise = EntitiesService.save(schedule)
            .then(function(ack) {
                return drawCalendarEvents({
                    load: true
                });
            })
            .then(function() {
                let events = getScheduleEvents(schedule._id);
                if (isCreatingSchedule) {
                    let event = events[0];
                    selectEventById(event.id);
                }
                drawCalendarEvents();
            });
        return promise;
    };

    this.deleteScheduleAction = function(command, isCreatingSchedule){
        const courseSchedule = command.entity;
        const promise = self.deleteSchedule(courseSchedule._id, courseSchedule._rev)
            .then(() => {
                drawCalendarEvents({
                    load: true
                });
                self.selectedEvent = null;
            })
            .catch(function(e) {
                AlertService.setAlert({
                    type: "danger",
                    msg: e.description || e
                });
            });
        return promise;
    };

    this.cancelScheduleAction = function(command, isCreatingSchedule){
        const courseSchedule = command.entity;
        courseSchedule.isCanceled = true;
        return this.saveScheduleAction(command, isCreatingSchedule);
    };

    this.deleteEventsAction = function(command){
        const promise = CalendarSchedulesService.deleteEvents(command.schedule, command.appointmentId, command.calEvent,
            command.selectedEvents)
        .then(() => {
            drawCalendarEvents({
                load: true
            });
            self.selectedEvent = null;
        })
            .catch(function(e) {
                AlertService.setAlert({
                    type: "danger",
                    msg: e.description || e
                });
            });
        return promise;
    };

    function getScheduleEvents(scheduleId) {
        let res = self.priv.events.filter(function(ev) {
            return ev.id.indexOf(scheduleId) == 0;
        });
        return res;
    }

    self.deleteSchedule = function(id, rev) {
        return EntitiesService.delete(id, rev);
    };

    self.buildModalMessage = function(isCreatingSchedule, schedule, appointment, isEditDisabled) {
        return self.buildStandardModalMessage(isCreatingSchedule, schedule, appointment, isEditDisabled);
    };

    self.buildStandardModalMessage = function(isCreatingSchedule, schedule, appointment, isEditDisabled) { //TODO: standardize names
        let message = {
            selectedActivityEvent: isCreatingSchedule ? null : self.selectedEvent,
            courseSchedule: schedule,
            lesson: isCreatingSchedule ? null : appointment,
            isCreatingSchedule: isCreatingSchedule,
            calendarEl: $('#courses-calendar'),
            calEvent: isCreatingSchedule ? null : self.selectedCalEvent,
            isEditDisabled: isEditDisabled //TODO: test edit disabled
        };
        return message;
    };

    function createEmptySchedule() {
        let schedule = angular.copy({
            _id: UtilsService.uuid(self.SCHEDULE_ID_PREFIX),
            schemaVersion: Constants.SCHEMA_VERSION,
            schedulingType: Constants.SCHEDULING_TYPE_APPOINTMENT,
            lessons: []
        });
        if (self.INITIAL_RESOURCE_ID) {
            schedule.roomId = self.priv.resourceId;
        }
        return schedule;
    }
}

angular.module('activitiesScheduleComponents')
    .component('cmScheduleCalendar', {
        templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/cm-schedule-calendar.template.html',
        controller: CmScheduleCalendarController,
        bindings: {
            resources: '<'
        }
    });
