function CmAppointmentsGridController($scope, $element, $attrs, UIService, uiGridConstants, MessageBox,
    CalendarSchedulesService, $timeout, Constants) {

    CmGridController.call(this, $scope, $element, $attrs, UIService, uiGridConstants);

    this.$onInit = function() {
        this.initProperties();
        this.defineGrid();

        if (this.activityEvent && this.activityEvent.activityScheduleHasAttendance) {
            this.disabledReason = "Cancella le presenze per modificare";
        }
    };

    this.createColumnDefs = function() {
        return columnDefs = [{
                name: "Inizio",
                field: "startDate",
                type: "date",
                cellFilter: "date:'EEE HH:mm'" //dd/MM/yyyy
                //width: 190
            },
            {
                name: "Fine",
                field: "endDate",
                type: "date",
                cellFilter: "date:'EEE HH:mm'"
                //width: 190
            }
        ];
    };

    this.afterGridApiRegistrered = function() {
        $timeout(
            () => {
                this.gridApi.selection.selectRow(this.selectedAppointment);
            }
        )
    };

    this.isAddItemDisabled = function() {
        return (this.activityEvent && this.activityEvent.activityScheduleHasAttendance);
    };

    this.addItem = function() {
        const startDate = moment(this.activitySchedule.startDate).hour(12).minutes(0).seconds(0).millisecond(0).toDate();
        const appointment = CalendarSchedulesService.createLesson(this.items, startDate);
        this.openEditDialog(appointment)
            .then(
                appointment => {
                    this.items.push(appointment);
                    this.onAppointmentsChanged();
                },
                () => {

                }
            );
    };

    this.openEditDialog = function(appointment) {
        let minDate = null;
        let maxDate = null;
        if (this.activitySchedule.schedulingType == Constants.SCHEDULING_TYPE_WEEKLY_APPOINTMENTS) {
            minDate = moment(this.activitySchedule.startDate).hour(0).minute(0).second(0).toDate();
            maxDate = moment(minDate).add(1, "weeks").toDate();
        }

        return MessageBox.open({
            templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/edit-appointment.modal.html',
            title: "Modifica Appuntamento",
            message: {
                appointment: appointment,
                minDate: minDate,
                maxDate: maxDate,
                getResult: function(scope) {
                    return scope.appointment
                }
            }
        })

    };

    this.isEditItemDisabled = function() {
        return (this.activityEvent && this.activityEvent.activityScheduleHasAttendance) ||
            UIService.getFirstSelectedRow(this.gridApi) == null;
    };

    this.editItem = function() {
        const selectedAppointment = UIService.getFirstSelectedRow(this.gridApi);
        this.openEditDialog(angular.copy(selectedAppointment))
            .then(
                appointment => {
                    angular.copy(appointment, selectedAppointment);
                    this.onAppointmentsChanged();
                },
                () => {

                }
            );
    };

    this.isDeleteItemDisabled = function() {
        var isRowSelected = (UIService.getFirstSelectedRow(this.gridApi) != null);
        return (this.activityEvent && this.activityEvent.activityScheduleHasAttendance) ||
            !isRowSelected || this.items.length <= 1;
    };


}

angular.module('activitiesScheduleComponents')
    .component('cmAppointmentsGrid', {
        templateUrl: 'components/form-components/cm-grid.template.html',
        controller: CmAppointmentsGridController,
        bindings: {
            items: '<',
            setItems: '&',
            activitySchedule: '<',
            selectedAppointment: '<',
            activityEvent: '<',
            onAppointmentsChanged: '&'
        }
    });
