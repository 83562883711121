"use strict";

twaCC.factory('ActivitiesSchedulesService', function($log, EntitiesService, $q, Constants, UtilsService,
    AssociatesDaoService, AttendanceService, Http) {

    var api = {

        drawCalendarEvents: function(start, end, selectedActivityEvent, opts) { //eventsSource, calendarEl

            function updateCalendar(activityEvents) {
                var visibleEvents = activityEvents.filter(function(ev) {
                    return ev.id.indexOf("hidden_") != 0;
                });
                var calEvents = api.buildCalEvents(visibleEvents, selectedActivityEvent);
                priv.activityCalEvents = calEvents;
                return calEvents;
            }

            if (!priv.roomId) {
                return;
            }
            if (opts && opts.load) {
                var roomId = priv.roomId;
                if (moment(end).diff(start, 'days') == 0) {
                    roomId = null;
                }
                return $q.all([
                        api.loadActivityEvents(start, end, roomId, opts.associateNamesMap)
                    ])
                    .then(function(data) {
                        var activityEvents = data[0];

                        priv.activityEvents = activityEvents;
                        return updateCalendar(activityEvents);
                    });
            } else {
                return $q.when(updateCalendar(priv.activityEvents));
            }

        },

        loadActivityEvents: function(fromDate, toDate, roomId, associateNamesMap) {
            return Http.get("api/activities/events/from/" + UtilsService.dateFormatISOLocalTime(fromDate) +
                    "/to/" + UtilsService.dateFormatISOLocalTime(toDate), {
                        params: {
                            roomId: roomId
                        }
                    })
                .then(activityEvents => {
                    activityEvents.forEach(event => {
                        event.participantsName = event.participants.map(participantId => associateNamesMap[participantId]);
                    });
                    return activityEvents;
                });
        },

        buildCalEvents: function(activityEvents, selectedActivityEvent) {
            return activityEvents.map(function(ev) {
                var title = ev.activityName;
                var endDate = ev.endDate;
                var start = moment(ev.startDate).format();
                var end = moment(endDate).format();
                if (ev.allDay) {
                    start = start.substring(0, 10);
                    end = end.substring(0, 10);
                }
                var event = {
                    id: ev.id,
                    title: title,
                    start: start,
                    end: end,
                    allDay: ev.allDay,
                    instructorId: ev.instructorId,
                    instructorName: ev.instructorName,
                    courseId: ev.activityScheduleId,
                    lessonId: ev.activityAppointmentId,
                    lessonIdx: ev.activityAppointmentIdx,
                    resourceId: ev.roomId,
                    color: ev.color,
                    schedulingType: ev.schedulingType,
                    scheduleStartDate: ev.scheduleStartDate,
                    scheduleEndDate: ev.scheduleEndDate,
                    notes: ev.notes,
                    className: []
                };

                if (selectedActivityEvent) {
                    if (selectedActivityEvent.id == ev.id) {
                        event.className.push('fc-event-selected');
                    } else if (selectedActivityEvent.activityScheduleId == ev.activityScheduleId) {
                        event.className.push('fc-schedule-selected');
                    }
                }
                //head events are not used anymore
                /*if (ev.repetition == Constants.REPETITION_WEEKLY && ev.isHeadEvent) {
                    event.className.push('fc-event-first');
                }*/
                if (ev.attendance) {
                    event.attendance = ev.attendance.attendance;
                    event.attendanceInstructorName = ev.attendance.instructorName;
                    event.attendanceDocId = ev.attendance.docId;
                    event.attendanceDocRev = ev.attendance.docRev;
                }
                if (ev.participants && ev.participants.length > 0) {
                    event.participantsName = ev.participantsName;
                }
                return event;
            });

        },


        setCurrentRoomId: function(roomId) {
            priv.roomId = roomId;
        },

        getCurrentRoomId: function() {
            return priv.roomId;
        },

        createEmptyCourse: function() {
            return angular.copy({
                _id: UtilsService.uuid("courseSchedule"),
                schemaVersion: Constants.SCHEMA_VERSION,
                courseId: null,
                roomId: priv.roomId,
                schedulingType: Constants.SCHEDULING_TYPE_APPOINTMENT,
                //participants: {},
                lessons: []
            });
        },

        getActivityEvent: function(id) {
            var res = priv.activityEvents.filter(function(ev) {
                return id == ev.id;
            });
            return res[0];
        },

        getCollaboratorActivityEvents: function(collaboratorId) {
            var res = priv.activityEvents.filter(function(ev) {
                return collaboratorId == ev.instructorId;
            });
            return res;
        },

        getActivityScheduleActivityEvents: function(activityScheduleId) {
            var res = priv.activityEvents.filter(function(ev) {
                return ev.id.indexOf(activityScheduleId) == 0;
            });
            return res;
        },

        getActivityCalEvent: function(id) {
            var res = priv.activityCalEvents.filter(function(ev) {
                return id == ev.id;
            });
            return res[0];
        },


        getActivityAppointment: function(activitySchedule, activityAppointmentId) {
            var res = activitySchedule.lessons.filter(function(app) {
                return activityAppointmentId == app.id;
            });
            return res[0];
        },

        resizeLesson: function(course, lesson, delta) {
            var endMillis = lesson.endDate.getTime();
            var deltaMillis = delta.asMilliseconds();
            lesson.endDate = new Date(endMillis + deltaMillis);
            //api._resizeCourseToIncludeLesson(course, lesson);
            api.compactActivityScheduleDates(course);
            return course;
        },

        /*
            make course start and end dates close to lessons interval
            (in case of weekly repetition course end date is free)
         */
        compactActivityScheduleDates: function(courseSchedule) {
            if (courseSchedule.lessons && courseSchedule.lessons.length != 0) {

                var firstLessonStartDate = courseSchedule.lessons.reduce(function(acc, lesson, lessonIdx) {
                    if (moment(lesson.startDate).isBefore(acc))
                        return lesson.startDate;
                    return acc;
                }, courseSchedule.lessons[0].startDate);

                var lastLessonEndDate = courseSchedule.lessons.reduce(function(prev, lesson) {
                    if (moment(lesson.endDate).isAfter(prev))
                        return lesson.endDate;
                    return prev;
                }, courseSchedule.lessons[0].endDate);


                if (courseSchedule.repetition == Constants.REPETITION_NONE ||
                    courseSchedule.schedulingType != Constants.SCHEDULING_TYPE_WEEKLY_APPOINTMENTS) {
                    courseSchedule.startDate = moment(firstLessonStartDate).toDate();
                    courseSchedule.endDate = moment(lastLessonEndDate).toDate();
                } else {

                }
            }
        },

        moveLesson: function(course, lesson, delta, allDay) {
            $log.info("moveLesson(" + course._id + ", " + lesson.id + ", " + delta + ", " + allDay);
            var deltaMillis = delta.asMilliseconds();
            var startMillis = lesson.startDate.getTime();
            // = eventStartMoment.toDate(); this gives problems with timezone
            var appointmentDate = new Date(startMillis + deltaMillis);
            lesson.startDate = appointmentDate;
            if (allDay) {
                lesson.startDate = moment(lesson.startDate).startOf("day").toDate();
                lesson.endDate = moment(lesson.startDate).add(1, 'day').toDate();
                lesson.allDay = true;
            } else {
                if (lesson.allDay) {
                    lesson.endDate = moment(lesson.startDate).add(2, "hour").toDate();
                } else {
                    var endMillis = lesson.endDate.getTime();
                    lesson.endDate = new Date(endMillis + deltaMillis);
                }
                delete lesson.allDay;
            }

            //api._resizeCourseToIncludeLesson(course, lesson);
            api.compactActivityScheduleDates(course);
        },

        /*
            Lessons are always in the first seven days after course start
            movements are relative (doesn't matter which event is selected) and constrained to one week
            this code should not be needed
         */
        compactLessons: function(courseSchedule) {
            if (courseSchedule.repetition == Constants.REPETITION_WEEKLY ||
                courseSchedule.schedulingType == Constants.SCHEDULING_TYPE_WEEKLY_APPOINTMENTS) {
                const courseStartWeek = moment(courseSchedule.startDate).week();;
                courseSchedule.lessons.forEach(function(lesson, idx) {
                    let week = courseStartWeek;
                    if (moment(lesson.startDate).day() < moment(courseSchedule.startDate).day()) {
                        week += 1;
                    }
                    lesson.startDate = moment(lesson.startDate).week(week).toDate();
                    lesson.endDate = moment(lesson.endDate).week(week).toDate();
                });
            }
        },

        /*
            muovo headevent
                verificare che non venga nascosto nessun appuntamento

         */

        isAppointmentDateVisible: function(appointmentDate, activityScheduleStartDate,
            activityScheduleEndDate, weeklyRepetition) {
            if (weeklyRepetition &&
                moment(activityScheduleEndDate).diff(activityScheduleStartDate, 'weeks') <= 1) {

                var firstCourseWeek = moment(activityScheduleStartDate)
                    .week();
                var dateInFirstWeek = moment(appointmentDate)
                    .week(firstCourseWeek);
                var lastCourseWeek = moment(activityScheduleEndDate)
                    .week();
                var dateInLastWeek = moment(appointmentDate)
                    .week(lastCourseWeek);

                if (!(
                        dateInFirstWeek.isBetween(activityScheduleStartDate, activityScheduleEndDate, 'day', '[]') ||
                        dateInLastWeek.isBetween(activityScheduleStartDate, activityScheduleEndDate, 'day', '[]')
                    )) {
                    return false;
                }
            }
            return true;
        },

        areAppointmentDatesAllVisible: function(activityScheduleStartDate, activityScheduleEndDate, courseSchedule) {
            var res = true;
            const weeklyRepetition = courseSchedule.schedulingType == Constants.SCHEDULING_TYPE_WEEKLY_APPOINTMENTS ||
                courseSchedule.repetition == Constants.REPETITION_WEEKLY;

            courseSchedule.lessons.forEach(function(lesson) {
                var visible = api.isAppointmentDateVisible(lesson.startDate,
                    activityScheduleStartDate, activityScheduleEndDate, weeklyRepetition);
                if (!visible) {
                    res = false;
                }
            });
            return res;
        },

        _resizeCourseToIncludeLesson: function(course, lesson) {
            var lessonIdx = course.lessons.indexOf(lesson);
            //only first lesson can be used to antedate the course start
            if (lessonIdx == 0) {
                if (moment(lesson.startDate).isBefore(course.startDate)) {
                    course.startDate = moment(lesson.startDate).startOf("day");
                }
            }
            if (moment(lesson.endDate).isAfter(course.endDate)) {
                course.endDate = moment(lesson.endDate).endOf("day").toDate();
            }
        },

        createLesson: function(lessons, lessonStartDate) {
            var startDate = lessonStartDate;

            var endDate = moment(startDate).add(1, 'hours').toDate();
            var id = 0;
            if (lessons.length > 0) {
                var lastLesson = lessons[lessons.length - 1];
                var tokens = lastLesson.id.split("_");
                var id = +tokens[1];
                if (id > 2147483647) { //bigger than maxint
                    id = lessons.length;
                }
                id += 1;
            }
            id = "lesson_" + id;
            const lesson = {
                id: id, //UtilsService.uuid("lesson"),
                startDate: startDate,
                endDate: endDate
            };
            return lesson;
        },

        addLesson: function(course, lessonStartDate) {
            const lesson = api.createLesson(course.lessons, lessonStartDate);
            course.lessons.push(lesson);
            $log.info("Added lesson: " + lesson.id + " to course: " + course._id);
            return course;
        },

        //TODO: create be api
        deleteLesson: function(activitySchedule, activityAppointmentId) {
            activitySchedule.lessons = activitySchedule.lessons.filter(function(el) {
                if (el.id == activityAppointmentId) {
                    return false;
                }
                return true;
            });
        },

        createLessonAttendance: function(activityEvent) {
            return window.shared.ActivitiesSchedulesShared.createLessonAttendance(activityEvent);
        },

        loadActivitySchedules: function(roomId) {
            return Http.get(`api/activities/schedules/room/${roomId}`)
        },

        loadSchedulingResources: function() {
            return EntitiesService.get("schedulingresources", "schedulingresources", {
                default: {
                    _id: "schedulingresources",
                    resources: [{
                        "_id": "sala_1",
                        "name": "Sala 1"
                    }]
                }
            });
        },

        loadParticipantAppointments: function(associateId) {
            return Http.get("api/activities/events/associate/"+associateId)
        }
    };

    var priv = {
        roomId: null,
        activityEvents: [],
        activityCalEvents: []
    };

    return api;
});
