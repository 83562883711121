"use strict";
// the effective file is in src/services, since it must be visible by club-manager and studiomanager
/*
asc.factory('ActivitiesSchedulesService', function($log, EntitiesService, $q, Constants, UtilsService,
                                                   AssociatesDaoService, AttendanceService, Http) {

    var api = {

        createLessonAttendance: function(activityEvent) {
            var courseSchedule_id = activityEvent.activityScheduleId;
            var tokens = activityEvent.activityAppointmentId.split("_");
            var id = tokens[1];
            var duration = moment.duration(moment(activityEvent.endDate).diff(activityEvent.startDate));
            var durationHours = duration.asHours();
            var attendanceStartMoment = moment(activityEvent.startDate);
            var attendanceStartStr = attendanceStartMoment.toJSON();
            return {
                _id: courseSchedule_id + "LessonAttendance" + "_" + id + "_" + attendanceStartStr,
                week: attendanceStartMoment.week(),
                attendance: 0,
                instructorId: activityEvent.instructorId,
                durationHours: durationHours
            }
        },

        loadLessonSchedulesInterval: function(roomId) {
            var options = {
                startkey: roomId,
                endkey: roomId,
                reduce: true,
                inclusive_end: true,
                group_level: 1,
                only_values: true,
            };
            return EntitiesService.loadView("activities", "lessons", options)
                .then(function(interval) {
                    return interval.length > 0 ? interval[0] : {};
                });
        },

        loadSchedulingResources: function() {
            return EntitiesService.get("schedulingresources", "schedulingresources", {
                default: {
                    _id: "schedulingresources",
                    resources: [{
                        "_id": "sala_1",
                        "name": "Sala 1"
                    }]
                }
            });
        },

        loadParticipantAppointments: function(associateId) {
            return Http.get("api/activities/events/associate/"+associateId)
        }

    };

    var priv = {
        roomId: null,
        events: [],
        activityCalEvents: []
    };

    return api;
});*/