function CmWorkScheduleCalendarController($scope, $element, $attrs, Constants, CalendarSchedulesService, Http, $q,
    $location, $log, $timeout, UtilsService, MessageBox, EntitiesService, $routeParams, $rootScope) {
    let self = this;

    CmScheduleCalendarController.call(this, $scope, $element, $attrs, Constants, CalendarSchedulesService, Http, $q,
        $location, $log, $timeout, UtilsService, MessageBox, EntitiesService, $routeParams, $rootScope);

    this.$onInit = function() {
        self.SCHEDULE_ID_PREFIX = "workSchedule";
        self.SCHEDULE_FIELD_IDENTIFYING_RESOURCE = 'collaboratorId';
        self.LOAD_EVENTS_URL_PREFIX = 'api/schedules/events/activities/workEvents';
        self.MODAL_TEMPLATE_URL = 'bower_components/twa-common-components/frontend/src/activities-schedule/workschedule-edit.html';
        self.MODAL_CONTROLLER = "CalendarScheduleModalController";
        self.VIEWS_NAME = ['timelineDay', 'timelineWeek', 'timelineMonth'];
        let calendarOptions = self.buildCalendarOptions();
        self.init(calendarOptions);
    };

    this._drawCalendarResources = function(callback) {
        let resources = self.resources.map(collaborator => {
            return {
                id: collaborator.id,
                title: collaborator.label
            }
        });
        if (callback){
            callback(resources);
        }
    };

    self.buildModalMessage = function(isCreatingSchedule, schedule, appointment, isEditDisabled) {
        let modalMessage = self.buildStandardModalMessage(isCreatingSchedule, schedule, appointment, isEditDisabled);
        modalMessage.collaborators = self.resources;
        return modalMessage;
    };

}

angular.module('activitiesScheduleComponents')
    .component('cmWorkScheduleCalendar', {
        templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/cm-schedule-calendar.template.html',
        controller: CmWorkScheduleCalendarController,
        bindings: {
            resources: '<',
        }
    });
