"use strict";

twaCC.factory('AttendanceService', function($log, EntitiesService, $q, Constants, UtilsService, Http) {

    var api = {

        saveAttendance: function(entity) {
            return Http.put("api/attendance/" + entity._id, entity)
                .then(function(resp) {
                    var ack = resp.data;
                    entity._rev = ack.rev;
                    return resp;
                })
        },

        createLessonAttendance: function(activityEvent) {
            return window.shared.AttendanceShared.createLessonAttendance(activityEvent);
        },

        loadDailyWeightRoomAttendance: function(date) {
            var id = api.weightRoomAttendanceId(date);
            return EntitiesService.get("dailyWeightRoomAttendance", id, {
                default: api.createDailyWeightRoomAttendance(date)
            })
        },

        createDailyWeightRoomAttendance: function(date) {
            return {
                _id: api.weightRoomAttendanceId(date),
                hourlyAttendance: []
            };
        },

        saveDailyWeightRoomAttendance: function(attendance) {
            return EntitiesService.save(attendance);
        },

        weightRoomAttendanceId: function(date) {
            return "weightRoomAttendance_" + date.toJSON().substring(0, 10);
        },

        loadAttendanceHours: function(startDate) {
            return Http.get(`/api/attendance/hours/${moment(startDate).format()}`);
        },

        loadMonthlyAggregatedAttendance: function(startDate) {
            var startDateStr = UtilsService.dateFormatISOLocalTime(startDate);
            var year = startDateStr.substring(0, 4);
            var month = startDateStr.substring(5, 7);
            return Http.get("/api/attendance/aggregated/year/" + year + "/month/" + month);
        },

        loadDailyAggregatedAttendance: function(startDate) {
            var startDateStr = UtilsService.dateFormatISOLocalTime(startDate);
            var yearMonth = startDateStr.substring(0, 7);
            return Http.get("/api/attendance/aggregated/" + yearMonth);
        }

    };

    return api;
});
