function CmWorkScheduleController($scope, $element, $attrs, Constants, CalendarSchedulesService, uiGridConstants,
    UIService, $location, AuthenticationService, MessageBox, UtilsService) {

    let self = this;

    CmScheduleController.call(this, $scope, $element, $attrs, Constants, CalendarSchedulesService, uiGridConstants,
        UIService, $location, AuthenticationService, MessageBox, UtilsService);

    this.$onInit = function() {
        self.init();
    };

}

angular.module('activitiesScheduleComponents')
    .component('cmWorkSchedule', {
        templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/cm-work-schedule.template.html',
        controller: CmWorkScheduleController,
        bindings: {
            selectedActivityEvent: '<',
            courseSchedule: '<',
            lesson: '<',
            isCreatingCourseSchedule: '<',
            calendarEl: '<',
            uibModalInstance: '<',
            collaborators: '<'
        }
    });
