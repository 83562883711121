function CmScheduleCalendarAddScheduleController($scope, EntitiesService, MessageBox, $log, $rootScope) {
    let self = this;

    this.$onInit = function() {};

    this.addSchedule = function() {
        $rootScope.$broadcast('addSchedule');
    };

    this.isAddScheduleDisabled = function() { //shouldn't be necessary
        // return $scope.currentRoomId == null;
        return false
    };
}

angular.module('activitiesScheduleComponents')
    .component('cmScheduleCalendarAddSchedule', {
        templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/cm-schedule-calendar-add-schedule.template.html',
        controller: CmScheduleCalendarAddScheduleController,
        bindings: {}
    });
