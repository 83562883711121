function CmActivityScheduleCalendarNotesController($scope, EntitiesService, MessageBox, $log) {
    let self = this;

    this.$onInit = function() {};

    $scope.$on('scheduleCalendarBeforeEventsRender', (event, calendarView) => {
        self.calendarView = calendarView;
        displayNotes(self.calendarView);

    });

    function displayNotes(calendarView) {
        if (self.calendarView.name == "agendaDay") {
            let newCalendarNote = _createCalendarNote();
            EntitiesService.get("calendarNotes", newCalendarNote._id, {
                    default: newCalendarNote
                })
                .then(
                    function(doc) {
                        self.schedulingNotes = doc;
                        _displayNotes(doc);
                    });

        } else {
            self.calendarNotesClasses = {
                "calendar-notes": true
            }
        }
    }

    function _createCalendarNote() {
        // let calendarView = $('#courses-calendar').fullCalendar('getView');
        let id = "calendarNotes_" + self.calendarView.start.format();
        return {
            _id: id
        };
    }

    function _displayNotes(doc) { //TODO: refactor calendar notes in its own component
        let active = (doc != null && doc.notes != null);
        self.calendarNotesClasses = {
            "calendar-notes": true,
            "calendar-notes-active": active,
            "calendar-notes-inactive": !active
        };
    }



    this.editSchedulingNotes = function() { //TODO refactor in edit scheduling notes component
        let schedulingNotes = self.schedulingNotes;

        let message = {
            schedulingNotes: schedulingNotes,
            getResult: function(scope) {
                return self.schedulingNotes;
            }
        };
        MessageBox.open({
                title: 'Note giornaliere',
                message: message,
                templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/schedulingnotes-edit.html',
                size: 'md'
            })
            .then(
                function ok(schedulingNotes) {
                    //$log.info("editSchedulingNotes: " + schedulingNotes);
                    if (schedulingNotes._rev &&
                        (schedulingNotes.notes == null || schedulingNotes.notes == "")) {
                        EntitiesService.delete(schedulingNotes)
                            .then(function(ack) {
                                self.schedulingNotes = _createCalendarNote();
                                _displayNotes($scope.schedulingNotes);
                            });
                    } else if (schedulingNotes.notes) {
                        EntitiesService.save(schedulingNotes)
                            .then(function(ack) {
                                _displayNotes(schedulingNotes);
                            })
                    }
                },
                function cancel(dismissReason) {
                    $log.info("dismissed: " + dismissReason);
                }
            );
    };
}

angular.module('activitiesScheduleComponents')
    .component('cmActivityScheduleCalendarNotes', {
        templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/cm-activity-schedule-calendar-notes.template.html',
        controller: CmActivityScheduleCalendarNotesController,
        bindings: {}
    });
