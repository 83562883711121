asc.controller('ActivitiesScheduleModalController', function($scope, title, message,
                                                             UtilsService, $controller, $uibModalInstance, Constants, $rootScope) {

    let self = this;
    $controller("CalendarScheduleModalController", {
        $scope: $scope,
        title: title,
        message: message,
        $uibModalInstance: $uibModalInstance
    });

    $scope.setAttendance = function(attendance) {
        $scope.attendance = attendance;
    };

    $scope.activeTabIdx = ($scope.selectedActivityEvent && $scope.selectedActivityEvent.activityScheduleHasAttendance) ? 1 : 0;

    $scope.isDeleteScheduleDisabled = function() {
        $scope.deleteCourseDisabledReason = null;
        // the user can delete a schedule with attendences
        // if ($scope.selectedActivityEvent && $scope.selectedActivityEvent.activityScheduleHasAttendance) {
        //     $scope.deleteCourseDisabledReason = "Cancella le presenze associate per poter cancellare l'attività";
        // }
        return $scope.isCreatingSchedule || $scope.deleteCourseDisabledReason != null;
    };

    $scope.beforeClose = function(actions){
        const attendanceModified = $scope.attendance ? UtilsService.wasModifiedAfterCheckpoint($scope.attendance) : false;
        if (attendanceModified) {
            actions.push({
                action: "saveActivityAttendance",
                entity: $scope.attendance
            });
        }
    };

});