"use strict";

// var app = angular.module('app');

twaCC.factory('UIService', function($q, $http, $log, $rootScope, UtilsService, AuthenticationService, Constants,
    $timeout) {

    var api = {
        confirmPageChange: function(scope, obj) {
            scope.$on('$locationChangeStart', function(event) {
                if (UtilsService.wasModifiedAfterCheckpoint(obj) && !confirm('Hai modifiche non salvate. Sicuro di voler uscire?')) {
                    event.preventDefault();
                }
            });
        },

        saveGridRow: function(rowEntity, promise, gridApi, entityName) {
            //var entity = angular.copy(rowEntity);
            promise = promise(rowEntity)
                .then(function(resp) {
                    //rowEntity._rev = rowEntity._rev;
                    $log.log(entityName + ' saved correctly');
                })
                .catch(function(error) {
                    $log.error(entityName + ' error: ' + error);
                });
            gridApi.rowEdit.setSavePromise(rowEntity, promise);
        },

        deleteSelectedGridRows: function(gridApi, options) {
            if (gridApi.grid.options.newRows == null) {
                gridApi.grid.options.newRows = 0;
            }
            var selectedRows = gridApi.selection.getSelectedGridRows()
                .map(function(gridRow) {
                    return gridRow.entity;
                });
            if (selectedRows) {
                if (options && options.deleteHandler) {
                    return $q.when(options.deleteHandler(selectedRows))
                        .then(function(confirmDelete) {
                            if (confirmDelete !== false) {
                                doDelete();
                            }
                        })
                } else {
                    $q.when(doDelete());
                }
            }

            function doDelete() {
                var newRowsDeleted = 0;
                var newModel = gridApi.grid.options.data.filter(function(el, idx) {
                    if (selectedRows.indexOf(el) != -1) {
                        if (gridApi.grid.options.headInsert) {
                            if (idx < gridApi.grid.options.newRows) {
                                newRowsDeleted += 1;
                            }
                        } else {
                            if (idx >= gridApi.grid.options.data.length - gridApi.grid.options.newRows) {
                                newRowsDeleted += 1;
                            }
                        }
                        return false;
                    } else {
                        return true;
                    }
                });
                UtilsService.shallowCopy(newModel, gridApi.grid.options.data);
                gridApi.grid.options.newRows -= newRowsDeleted;
            }
        },

        deleteGridRows: function(gridApi, rows) {
            if (rows) {
                var newModel = gridApi.grid.options.data.filter(function(el, idx) {
                    return rows.indexOf(el) == -1;
                });
                UtilsService.shallowCopy(newModel, gridApi.grid.options.data);
            }
        },

        addGridRow: function(gridApi, entity) {
            if (gridApi.grid.options.newRows == null) {
                gridApi.grid.options.newRows = 0;
            }
            if (gridApi.grid.options.headInsert) {
                gridApi.grid.options.data.unshift(entity);
            } else {
                gridApi.grid.options.data.push(entity);
            }
            gridApi.grid.options.newRows += 1;
        },

        isNewRow: function($scope) {
            var rowIndex = $scope.grid.options.data.indexOf($scope.row.entity);

            var res;
            if ($scope.grid.options.headInsert) {
                //new rows are added only at the start of data
                res = rowIndex <= $scope.grid.options.newRows;
            } else {
                //new rows are added only at the end of data
                res = rowIndex >= $scope.grid.options.data.length - $scope.grid.options.newRows;
            }
            if (res) {
                return true;
            } else {
                return false;
            }
        },

        /* currently not used
        isDeletableRow: function(row) {
            var isNewRow = api.isNewRow({
                row: row,
                grid: row.grid
            })

            var userIsAdmin = $rootScope.authority.userRole == Constants.USER_ROLE_ADMINISTRATOR;//AuthenticationService.currentUserHasRole(Constants.USER_ROLE_ADMINISTRATOR);
            var canDeleteRow = isNewRow || userIsAdmin;
            return canDeleteRow;
        },

        canDeleteSelectedRows: function(gridApi) {
            var selectedRows = gridApi.selection.getSelectedGridRows();
            var selectedDeletableRows = selectedRows.filter(api.isDeletableRow);
            var canDelete = selectedRows.length > 0 && selectedDeletableRows.length == selectedRows.length;
            return canDelete;
        },
        */

        showTrueFalse: function(value) {
            if (value == true)
                return '<span class="glyphicon glyphicon-ok" aria-hidden="true"></span>';
            return '<span class="glyphicon glyphicon-minus" aria-hidden="true"></span>';
        },

        getComputeCellClass: function(additionalRowClasses) {
            return function(grid, row, col, $scope) {
                if (row != grid.lastRenderedRow || grid.options.data.length == 1) {
                    row.classes = null;
                }
                if (!row.classes) {
                    var rowClasses = {};
                    if (additionalRowClasses) {
                        row.additionalRowClasses = additionalRowClasses(grid, row);
                        angular.merge(rowClasses, row.additionalRowClasses);
                    }
                    row.classes = rowClasses;
                }
                var conflict = false;
                if ($scope.row.entity.$$conflict) {
                    conflict = $scope.row.entity.$$conflict.indexOf(col.field) != -1;
                }
                var cellClasses = {
                    'ui-grid-row-header-cell': col.isRowHeader,
                    'conflict': conflict
                };
                angular.merge(cellClasses, row.additionalRowClasses);
                grid.lastRenderedRow = row;
                return cellClasses;
            };
        },

        getSelectedRows: function(gridApi) {
            var selectedRows = gridApi ?
                gridApi.selection.getSelectedGridRows()
                .map(function(gridRow) {
                    return gridRow.entity;
                }) : [];
            return selectedRows;
        },

        getFirstSelectedRow: function(gridApi) {
            var selectedRows = gridApi ?
                gridApi.selection.getSelectedGridRows()
                .map(function(gridRow) {
                    return gridRow.entity;
                }) : [];
            if (selectedRows.length > 0) {
                return selectedRows[0];
            } else {
                return null;
            }
        },

        ROW_TEMPLATE_COMPUTING_CLASS: '<div>' +
            '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ' +
            'class="ui-grid-cell" ng-class="grid.options.computeCellClasses(grid, row, col, this.$parent)" ui-grid-cell></div></div>',

        ROW_TEMPLATE_DRAGGABLE_COMPUTING_CLASS: '<div grid="grid" class="ui-grid-draggable-row" draggable="true">' +
            '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ' +
            'class="ui-grid-cell" ng-class="grid.options.computeCellClasses(grid, row, col, this.$parent)" ui-grid-cell></div></div>',


        computeInputUrl: function(entity, otherparams) {
            var idTokens = entity._id.split("_");
            var idPrefix = idTokens[0];
            if (idTokens.length == 3)
                idPrefix = idTokens[0] + "_" + idTokens[1];
            var url = idPrefix.split("-").map(function(token) {
                return _.snakeCase(token);
            }).join("/");
            var params = [];
            if (entity.rateIdx !== undefined) {
                params.push("rateIdx=" + entity.rateIdx);
            }
            /*if (row.grid.options.cellTemplateReferrer){
             params.push("referrer="+row.grid.options.cellTemplateReferrer);
             }*/
            if (otherparams) {
                params = params.concat(otherparams);
            }
            var paramsStr = params.join("&");
            return "#!/inputs/" + url + "/" + entity._id + "?" + paramsStr;
        },

        computeRecordUrl: function(entity, otherparams) {
            var idPrefix = entity._id.split("_")[0];
            var url = idPrefix.split("-").map(function(token) {
                return _.snakeCase(token);
            }).join("/");
            return "#!/configurations/" + url + "/" + entity._id;
        },

        getComputeItemUrlFun: function(itemType) {
            return function(entity, otherparams) {
                var idPrefix = entity._id.split("_")[0];
                var url = idPrefix.split("-").map(function(token) {
                    return _.snakeCase(token);
                }).join("/");
                return "#!/" + itemType + "/" + url + "/" + entity._id;
            }
        },

        INPUT_DETAILS_CELL_TEMPLATE: '<div class="ngCellText" ng-class="col.colIndex()">' +
            '  <a href="{{grid.options.computeInputUrl(row.entity, row)}}">Dettagli</a>' +
            '</div>',

        getInputDetailsCellTemplate: function(params) {
            var template = '<div class="ngCellText text-center" ng-class="col.colIndex()"' +
                'style="display: table; width:100%; height: 100%">' +
                '  <a href=\'{{grid.options.computeInputUrl(row.entity, ' + params + ')}}\'' +
                '   class="details-link-cell glyphicon glyphicon-eye-open"></a>' +
                '</div>';
            return template;
            //return api.getDetailsCellTemplate({{grid.options.computeInputUrl(row.entity, params )}}")
        },

        getDetailsCellTemplate: function(href) {
            var template = '<div class="ngCellText text-center" ng-class="col.colIndex()" ' +
                'style="display: table; width:100%; height: 100%">' +
                '  <a href="' + href + '" class="details-link-cell glyphicon glyphicon-eye-open"></a>' +
                '</div>';
            return template;
        },

        changeButtonState: function(scope, prefix, btnClass) {
            scope[prefix + "Class"] = ["btn", btnClass];
            $timeout(function() {
                scope[prefix + "Disabled"] = false;
                scope[prefix + "Class"] = ["btn", "btn-default"];
            }, 1000);
        },

        gridFromDateFilter: {
            condition: function(term, value, row, column) {
                if (!term) return true;
                term = term.replace(/\\/g, "");
                if (typeof value == "object") {
                    value = moment(value).format();
                }
                return value >= term;
            },
            placeholder: 'data inizio'
        },

        gridToDateFilter: {
            condition: function(term, value, row, column) {
                if (!term) return true;
                term = term.replace(/\\/g, "");
                if (typeof value == "object") {
                    value = moment(value).format();
                }
                return value.substring(0, term.length) <= term;
            },
            placeholder: 'data fine'
        }
    };

    return api;
});
