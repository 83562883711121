function CmActivityScheduleCalendarPreferencesController($scope, EntitiesService, MessageBox, $log, $rootScope) {
    let self = this;

    this.$onInit = function() {};

    this.editPreferences = function() {
        var message = {};
        return MessageBox.open({
                title: 'Preferenze',
                message: message,
                templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/scheduling-preferences.html',
                size: 'lg',
                controller: "ActivitiesSchedulePreferencesModalController"
            })
            .then(function() {
                $rootScope.$broadcast('drawCalendarEvents', {
                    load: true
                });
            });
    };
}

angular.module('activitiesScheduleComponents')
    .component('cmActivityScheduleCalendarPreferences', {
        templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/cm-activity-schedule-calendar-preferences.template.html',
        controller: CmActivityScheduleCalendarPreferencesController,
        bindings: {}
    });
