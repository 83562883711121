asc.controller('SchedulingResourcesModalController', function($scope, $uibModalInstance, title, message,
    UIService, ActivitiesSchedulesService, MessageBox, AlertService, Constants, Http) {

    $scope.alerts = AlertService;
    //if message is an object, put all its properties in $scope
    if (angular.isObject(message)) {
        angular.extend($scope, message);
    } else {
        $scope.message = message;
    }

    $scope.title = title;

    $scope.roomsGridOptions = {
        columnDefs: [{
                field: "order",
                name: "Ordine",
                cellTemplate: '<div class="ui-grid-cell-contents"> <span class="ui-grid-draggable-row-handle glyphicon glyphicon-sort" aria-hidden="true"></span></div>',
                maxWidth: 70,
            },
            {
                field: "_id",
                name: "Id",
                //width: 150,
                headerTooltip: true,
                enableCellEdit: false
            },
            {
                field: "name",
                name: "Nome",
                //width: 200,
                headerTooltip: true
            },
            {
                field: "color",
                name: "Colore",
                //width: 120,
                cellTemplate: '<div class="ui-grid-cell-contents" ><div style="width:20px; height: 20px; background-color: {{grid.getCellValue(row, col)}}">&nbsp;</div></div>',
                editableCellTemplate: 'ui-grid/dropdownEditor',
                editDropdownOptionsArray: Constants.COLOR_OPTIONS
            }
        ],
        multiSelect: false,
        rowTemplate: '<div grid="grid" class="ui-grid-draggable-row" draggable="true"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, \'custom\': true }" ui-grid-cell></div></div>',
        useUiGridDraggableRowsHandle: true,
        data: $scope.resources,
        onRegisterApi: function(gridApi) {
            $scope.roomsGridApi = gridApi;

            $scope.deleteSelectedRoomDisabled = false; //true;
            /*gridApi.selection.on.rowSelectionChanged($scope, function(row) {
             if (row.isSelected) {
             var roomId = row.entity._id;
             CoursesSchedulesService.loadCourseSchedulesForRoom(roomId)
             .then(function(schedules) {
             if (schedules.length > 0) {
             $scope.deleteSelectedRoomDisabled = true;
             } else {
             $scope.deleteSelectedRoomDisabled = false;
             }
             });
             } else {
             $scope.deleteSelectedRoomDisabled = true;
             }

             });*/
        }
    };

    //TODO: enter button should trigger this action
    $scope.addRoom = function(roomId) {
        var existingIds = $scope.resources.map(function(el) {
            return el._id;
        });
        if (existingIds.indexOf(roomId) == -1) {
            var room = {
                _id: roomId,
                name: roomId
            };
            UIService.addGridRow($scope.roomsGridApi, room);
        }
        $scope.roomId = null;
    };

    $scope.deleteSelectedRoom = function() {
        var row = UIService.getFirstSelectedRow($scope.roomsGridApi)
        var roomId = row._id;
        if ($scope.resources.length == 1) {
            AlertService.setAlert({
                type: "danger",
                msg: "Impossibile cancellare la risorsa. Ne deve rimanere almeno una"
            }, 'schedulingResources');
        }
        return loadActivitySchedules(roomId)
            .then(function(interval) {
                if (interval.startDate) {
                    AlertService.setAlert({
                        type: "danger",
                        msg: "Impossibile cancellare la risorsa. Cancellare prima  la programmazione"
                    }, 'schedulingResources');
                } else {
                    UIService.deleteGridRows($scope.roomsGridApi, [row]);
                }
            });
    };

    function loadActivitySchedules(roomId) {
        return Http.get(`api/activities/schedules/room/${roomId}`)
    }

    $scope.ok = function(courseSchedule, form) {
        return $uibModalInstance.close($scope.getResult());
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };


    $scope.getResult = function() {
        return $scope.resources
    };
    console.log(title, message);
});
