if (typeof window == "undefined") {
    var moment = require("moment");
    var _ = require('lodash');
}

var api = {

    isQuotaAssociativaPayed: function(summary) {
        if (summary.quotaAssociativaDate) {
            if (moment().isSameOrBefore(summary.quotaAssociativaExpirationDate, 'day')) {
                return true;
            }
        }
        return false;
    }
};

if (typeof window == "undefined") {
    module.exports = api;
} else {
    if (!window.shared) {
        window.shared = {};
    }
    window.shared.AssociatesSummaryShared = api;
}
