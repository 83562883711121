function CmParticipantAppointmentsController($scope, $q, Http, $log, EntitiesService, MessageBox) {
    let self = this;

    this.$onInit = function () {
        let calendarOptions = self.buildCalendarOptions();
        self.init(calendarOptions)
    };

    //TODO: abilitare quando c'è un appuntamento selezionato
    $scope.$on('deleteCalendarAppointment', (event, opts) => {
        if (self.selectedEvent.isSingleEvent){

            MessageBox.open({
                title: "Conferma cancellazione",
                subtitle: null,
                templateType: null
            })
                .then(function() {

                    let command = {
                        entity: {
                            _id: self.selectedEvent.activityScheduleId,
                            _rev: self.selectedEvent.activityScheduleRev
                        }
                    };
                    self.deleteScheduleAction(command);
                });


        } else {
            $log.debug("won't delete because it's not a single event");
        }
    });

    this.deleteScheduleAction = function(command, isCreatingSchedule){
        const courseSchedule = command.entity;
        const promise = self.deleteSchedule(courseSchedule._id, courseSchedule._rev)
            .then(() => {
                drawCalendarEvents({
                    load: true
                });
                self.selectedEvent = null;
            })
            .catch(function(e) {
                AlertService.setAlert({
                    type: "danger",
                    msg: e.description || e
                });
            });
        return promise;
    };

    self.deleteSchedule = function(id, rev) {
        return EntitiesService.delete(id, rev);
    };

    this.buildCalendarOptions = function() {
        return {
            //height: 'parent',
            aspectRatio: 0.10,
            defaultView: 'listAll',
            header: {
                left: '',
                center: '',
                right: ''
            },
            visibleRange: {
                start: '2000-01-01',
                end: '2200-01-01'
            },
            views: {
                listAll: {
                    type: 'list',

                    buttonText: '4 day'
                }
            },

            eventClick: onEventClick,

            lazyFetching: false,
            eventAfterRender: self._eventAfterRender,
            eventAfterAllRender: eventAfterAllRender,

            events: _drawCalendarEvents, //events
        }
    }

    function eventAfterAllRender(view) {
        var eventosRendered = $('#courses-calendar tr');
        var eventosInversa = [];
        var headingPendiente = null;
        eventosRendered.map(function(key, evento) {
            switch(evento.className) {
                case 'fc-list-heading':
                    if (headingPendiente) {
                        eventosInversa.unshift(headingPendiente);
                    }
                    headingPendiente = evento;
                    break;
                case 'fc-list-item':
                case 'fc-list-item fc-event-selected':
                    eventosInversa.unshift(evento);
                    break;
            }
        });
        eventosInversa.unshift(headingPendiente);

        $('#courses-calendar tbody').append(eventosInversa);
    }

    this.init = function(calendarOptions) {
        self.priv = {
            events: [],
            calEvents: [],
            resourceId: self.INITIAL_RESOURCE_ID,
            backgroundResourceId: null
        };
        $(document)
            .ready(function() {
                $('#courses-calendar')
                    .fullCalendar(calendarOptions);
            });
    };

    function onEventClick(calEvent, jsEvent, view) {
        $log.debug("event click");
        selectEvent(calEvent);
        drawCalendarEvents();
    }

    function selectEvent(calEvent) {
        selectEventById(calEvent.id);
    }

    function selectEventById(calEventId) {
        //after a move/resize calEvent changes
        self.selectedCalEvent = getCalEvent(calEventId);
        self.selectedEvent = getEvent(calEventId);

        $log.info("selectedEvent: " + JSON.stringify(self.selectedEvent));
    }

    function getCalEvent(id) {
        let res = self.priv.calEvents.filter(function(ev) {
            return id == ev.id;
        });
        return res[0];
    }

    function getEvent(id) {
        let res = self.priv.events.filter(function(ev) {
            return id == ev.id;
        });
        return res[0];
    }

    this._eventAfterRender = function(event, element, view) {
        let tooltipTitle = event.title;

        let text = "";
        if (event.instructorName) {
            text += "<h6>gestita da:</h6>" +
                "<div style='padding-left: 20px;'>" + event.instructorName + "</div>";
        }
        if (event.participants) {
            text += "<h6>partecipanti: </h6>" +
                "<ul>";
            event.participants.forEach(function(participant) {
                text += "<li>" + participant + "</li>";
            });
            text += "</ul>";
        }
        if (event.notes) {
            text += "<h6>Note</h6>" +
                "<pre>" + event.notes + "</pre>";
        }

        let isTouchDevice = ('ontouchstart' in document.documentElement);
        const showEvent = isTouchDevice ? "taphold" : "mouseenter"; //disabling for touch devices
        element.qtip({
            content: {
                title: {
                    text: tooltipTitle,
                },
                text: text
            },
            position: {
                my: 'bottom center',
                at: 'top center',
                viewport: $('#courses-calendar')
            },
            show: {
                event: showEvent,
                effect: function() {
                    $(this).fadeTo(500, 1);
                },
                solo: true
            },
            style: {
                classes: "qtip-default qtip-rounded qtip-shadow",
            },
            hide: {
                fixed: false,
                effect: function() {
                    $(this).fadeTo(500, 0);
                },
                //event: 'unfocus'
            }

        });
    };

    this.removeTooltips = function() {
        $('.qtip')
            .each(function() {
                let tooltip = $(this);
                if (tooltip.attr('style')) {
                    tooltip.remove();
                }
            });
    };

    let loadEventsFromServer = true;
    let loadEventsDeferred = null;

    $scope.$on('reloadAppointments', () => {
        self.drawCalendarEvents({load: true})
    });

    function drawCalendarEvents(opts) {
        loadEventsFromServer = false;
        if (opts) {
            loadEventsFromServer = opts.load;
        }
        $('#courses-calendar')
            .fullCalendar('refetchEvents');
        loadEventsFromServer = true;
        if (opts && opts.load) {
            loadEventsDeferred = $q.defer();
            return loadEventsDeferred.promise;
        } else {
            return $q.when(null);
        }
    }
    this.drawCalendarEvents = drawCalendarEvents;


    function _drawCalendarEvents(start, end, timezone, callback) {
        let opts = {
            load: loadEventsFromServer
        };
        return fetchCalendarEvents(self.selectedEvent, opts)    //$q.when(events)
            .then(calEvents => {
                callback(calEvents);
            });
    }

    function fetchCalendarEvents(selectedEvent, opts){
        if (opts && opts.load) {
            return Http.get("api/activities/events/associate/" + self.associateId)
                .then(events => {
                    self.priv.events = events;
                    let calEvents = buildCalEvents(events);
                    self.priv.calEvents = calEvents;
                    return calEvents;
                })
        } else {
            let calEvents = buildCalEvents(self.priv.events, selectedEvent);
            self.priv.calEvents = calEvents;
            return $q.when(calEvents);
        }
    }

    function buildCalEvents(events, selectedEvent){
        let calEvents = events.map(ev => {
            let start = moment(ev.startDate)
                .format();
            let end = moment(ev.endDate)
                .format();
            if (ev.allDay) {
                start = start.substring(0, 10);
                end = end.substring(0, 10);
            }
            let event = {
                id: ev.id,
                title: ev.activityName,
                start: start,
                end: end,
                className: [],

                //decorate
                instructorName: ev.instructorName,
                participants: ev.participants,
                notes: ev.notes
            };
            if (selectedEvent) {
                if (selectedEvent.id == ev.id) {
                    event.className.push('fc-event-selected');
                } else if (selectedEvent.activityScheduleId == ev.activityScheduleId) {
                    event.className.push('fc-schedule-selected');
                }
            }
            return event;
        });
        return calEvents;
    }
}

angular.module('activitiesScheduleComponents')
    .component('cmParticipantAppointments', {
        templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/cm-participant-appointments.template.html',
        controller: CmParticipantAppointmentsController,
        bindings: {
            associateId: '<'
        }
    });
