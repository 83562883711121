"use strict";

asc.factory('CalendarSchedulesService', function($log, EntitiesService, $q, Constants, UtilsService,
                                                 AssociatesDaoService, AttendanceService, Http) {

    let api = {
        resizeLesson: function(course, lesson, delta) {
            var endMillis = lesson.endDate.getTime();
            var deltaMillis = delta.asMilliseconds();
            lesson.endDate = new Date(endMillis + deltaMillis);
            //api._resizeCourseToIncludeLesson(course, lesson);
            api.compactActivityScheduleDates(course);
            return course;
        },

        /*
            make course start and end dates close to lessons interval
            (in case of weekly repetition course end date is free)
         */
        compactActivityScheduleDates: function(courseSchedule) {
            window.shared.CalendarSchedulesShared.compactActivityScheduleDates(courseSchedule);
        },

        moveLesson: function(selectedSchedule,
                             selectedAppointment, timeDelta, calEvent, scheduleFieldIdentifyingResource,
                             resourceId, selectedEvents) {
            //const appointmentIdx = selectedAppointment.id.replace("lesson_", "")
            let payload = {
                selectedSchedule: selectedSchedule,
                appointmentId: selectedAppointment.id,
                timeDelta: timeDelta,
                calEvent: calEvent,
                scheduleFieldIdentifyingResource: scheduleFieldIdentifyingResource,
                resourceId: resourceId,
                selectedEvents: selectedEvents
            };
            return Http.post("api/schedules/move_event", payload);
        },

        /*
            Lessons are always in the first seven days after course start
            movements are relative (doesn't matter which event is selected) and constrained to one week
            this code should not be needed
         */
        compactLessons: function(courseSchedule) {
            return window.shared.CalendarSchedulesShared.compactLessons(courseSchedule);
        },

        isAppointmentDateVisibleAfterMove: function(appointmentStartDate, activityScheduleStartDate,
                                                    activityScheduleEndDate, isWeeklyRepetition) {
            return window.shared.CalendarSchedulesShared.isAppointmentDateVisibleAfterMove(appointmentStartDate,
                activityScheduleStartDate, activityScheduleEndDate, isWeeklyRepetition);
        },

        /*areAppointmentDatesAllVisible: function(activityScheduleStartDate, activityScheduleEndDate, courseSchedule) {
            var res = true;
            const weeklyRepetition = courseSchedule.schedulingType == Constants.SCHEDULING_TYPE_WEEKLY_APPOINTMENTS ||
                courseSchedule.repetition == Constants.REPETITION_WEEKLY;

            courseSchedule.lessons.forEach(function(lesson) {
                var visible = api.isAppointmentDateVisibleAfterMove(lesson.startDate,
                    activityScheduleStartDate, activityScheduleEndDate, weeklyRepetition);
                if (!visible) {
                    res = false;
                }
            });
            return res;
        },*/

        _resizeCourseToIncludeLesson: function(course, lesson) {
            var lessonIdx = course.lessons.indexOf(lesson);
            //only first lesson can be used to antedate the course start
            if (lessonIdx == 0) {
                if (moment(lesson.startDate).isBefore(course.startDate)) {
                    course.startDate = moment(lesson.startDate).startOf("day");
                }
            }
            if (moment(lesson.endDate).isAfter(course.endDate)) {
                course.endDate = moment(lesson.endDate).endOf("day").toDate();
            }
        },

        createLesson: function(lessons, lessonStartDate) {
            var startDate = lessonStartDate;

            var endDate = moment(startDate).add(1, 'hours').toDate();
            var id = 0;
            //generate unique lesson id
            if (lessons.length > 0) {
                var lastLesson = lessons[lessons.length - 1];
                var tokens = lastLesson.id.split("_");
                var id = +tokens[1];
                /*if (id > 2147483647) { //bigger than maxint
                    id = lessons.length;
                }*/
                id += 1;
            }
            id = "lesson_" + id;
            const lesson = {
                id: id,
                startDate: startDate,
                endDate: endDate
            };
            return lesson;
        },

        addLesson: function(course, lessonStartDate) {
            const lesson = api.createLesson(course.lessons, lessonStartDate);
            course.lessons.push(lesson);
            $log.info("Added lesson: " + lesson.id + " to course: " + course._id);
            return course;
        },

        //TODO: create be api
        deleteLesson: function(activitySchedule, activityAppointmentId) {
            activitySchedule.lessons = activitySchedule.lessons.filter(function(el) {
                if (el.id == activityAppointmentId) {
                    return false;
                }
                return true;
            });
        },

        deleteEvents: function(selectedSchedule,
                             selectedAppointment, calEvent, selectedEvents) {
            let payload = {
                selectedSchedule: selectedSchedule,
                appointmentId: selectedAppointment,
                calEvent: calEvent,
                selectedEvents: selectedEvents
            };
            return Http.post("api/schedules/delete_events", payload);
        },
    };

    return api;
});